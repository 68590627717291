import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PagerService } from 'src/app/core/common/pager.service';
import {
  StorageKey,
  StorageService,
} from 'src/app/core/common/storage.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CandidateService } from 'src/app/core/services/candidates/candidate.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { ProjectService } from 'src/app/core/services/projects/project.service';
import { TagserviceService } from 'src/app/core/services/tags/tagservice.service';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-candidate-status-list',
  templateUrl: './candidate-status-list.component.html',
  styleUrls: ['./candidate-status-list.component.css'],
})
export class CandidateStatusListComponent implements OnInit {
  FilterForm!: FormGroup;
  UpdateCandidateStatusForm!: FormGroup;
  showCreateButton: string = 'candidate-status';
  pageTitle: string = 'Candidate Status';
  pageNumber: number = 1;
  pageSize: number = 3;
  totalRows: number = 0;
  pager: any = {};
  currentSearchValue: any;
  candidateStatusList: any = [];
  candidateStatusSequence: any = [];
  submitted: boolean = false;
  iconVisible: boolean = true;
  iconVisibleAsc: boolean = false;
  iconVisibleDesc: boolean = false;
  staticarrowsVisible: boolean = false;
  currentSortedColumn: any;
  currentSortedMethod: any;
  sortedColumn: any;
  sortfield: any;
  sortmethod: any;
  mouseOnColumnName: string = '';
  faArrowUp = faCaretUp;
  faArrowDown = faCaretDown;
  multipleSorting: any = [];
  getSearchList: any;

  imagepath: string = '';
  strengthId: any;
  imageselected: boolean = false;
  filterStrengthId: string = '';

  showFilterOptions: boolean = false;
  closeResult: string = '';
  updateCSErrorName: string = '';
  updateCSErrorSequence: string = '';
  updateCSErrorDescription: string = '';
  updateCSError: string = '';
  csapicall: boolean = false;
  updateCandidateStatusData: any;
  createTagError: any;
  userRole = this.storageService.getValue(StorageKey.currentUserRoleId);
  currentTenantId = this.storageService.getValue(StorageKey.currentTenantId);
  deleteCandidateStatusId: number = 0;

  get filterForm() {
    return this.FilterForm.controls;
  }
  get updateCandidateStatusForm() {
    return this.UpdateCandidateStatusForm.controls;
  }

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authservice: AuthService,
    private storageService: StorageService,
    private candidateStatusService: CandidateService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private pagerService: PagerService
  ) {}

  ngOnInit(): void {
    this.onBuildForm();
    this.onLoad();
  }

  onBuildForm() {
    this.UpdateCandidateStatusForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      description: [null],
      sequence: [null, [Validators.pattern('^[0-9]*$')]],
      status: [null, [Validators.required]],
      updateId: [null],
    });
    if (this.currentTenantId != '0') {
      this.updateCandidateStatusForm['name'].disable();
      this.updateCandidateStatusForm['description'].disable();
    } else {
      this.updateCandidateStatusForm['name'].enable();
      this.updateCandidateStatusForm['description'].enable();
    }
  }

  onLoad() {
    let listSettings: any = this.storageService.getValue(
      StorageKey.listSettings
    );
    if (listSettings !== 'undefined' && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.candidates) {
      let existing_filter = this.getSearchList.candidates.apply_filter;
      if (existing_filter) {
        this.showFilterOptions = true;
        let apply_filter = existing_filter.split('&');
        apply_filter.forEach((e: any) => {
          let f_option = e.split('=');
          if (f_option[0] == 'search_current_position')
            this.FilterForm.controls['f_position'].setValue(f_option[1]);

          if (f_option[0] == 'search_current_company')
            this.FilterForm.controls['f_company'].setValue(f_option[1]);

          if (f_option[0] == 'search_in_gender')
            this.FilterForm.controls['f_gender'].setValue(f_option[1]);

          if (f_option[0] == 'search_custom_diversity')
            this.FilterForm.controls['f_diversity'].setValue(f_option[1]);

          if (f_option[0] == 'search_custom_compensation')
            this.FilterForm.controls['f_compensation'].setValue(f_option[1]);

          if (f_option[0] == 'language_id')
            this.FilterForm.controls['f_language'].setValue(f_option[1]);

          if (f_option[0] == 'tag_id')
            this.FilterForm.controls['f_tag'].setValue(f_option[1]);

          if (f_option[0] == 'strength')
            this.showfilterimage(
              './assets/img/' + f_option[1] + '.png',
              f_option[1]
            );
        });
      }
      this.getCandidateStatusList(null, this.getSearchList.candidates_status);
    } else this.getCandidateStatusList({});
  }

  resetListSettings() {
    let listSettings: any = this.storageService.getValue(
      StorageKey.listSettings
    );
    if (listSettings !== 'undefined' && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.clients) {
      delete this.getSearchList.candidate_status;
      this.storageService.setValue(
        'listSettings',
        JSON.stringify(this.getSearchList)
      );
    }
    this.currentSearchValue = '';
    this.getCandidateStatusList({}, undefined, true);
  }

  getCandidateStatusList(
    data1: any,
    data = {
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      //apply_filter:this.currentAppliedFilter,
      search: this.currentSearchValue,
      //multipleSorting:this.multipleSorting
    },
    reset: boolean = false
  ) {
    if (data1) {
      data.sort_field = data1.sort_field;
      data.sort_method = data1.sort_method;
      data.column_name = data1.column_name;
      data.page = data1.page;
      if (data1.sort_field && data1.sort_method) {
        // this.multipleSorting[data1.sort_field] = [data1.sort_field, data1.sort_method];
        // data.multipleSorting = this.multipleSorting;
      }
    }

    //data={page :this.pageNumber, sort_field: string = '', sort_method: string ='', column_name: string=''}
    if (reset) {
      /*  if(this.getSearchList) {
         this.currentSearchValue = data.search;
         this.getSearchList.candidates =  data; 
         this.storageService.setValue('listSettings', JSON.stringify(this.getSearchList));
       } else 
         this.storageService.setValue('listSettings', JSON.stringify({candidates:data}));
     }else{
       data.search = ""; */
      data.page = 1;
    }

    this.candidateStatusService.candidateStatusListByFilter(data).subscribe({
      next: (response) => {
        this.candidateStatusList = response.candidate_statuses.data;
        this.candidateStatusSequence = this.candidateStatusList.map(
          (data: any) => data.sequence
        );
        console.log(this.candidateStatusSequence);

        this.pageNumber = response.candidate_statuses.current_page;
        this.totalRows = response.candidate_statuses.total;
        this.pager = this.pagerService.getPager(
          this.totalRows,
          this.pageNumber
        );
        if (data.sort_field) {
          this.iconVisible = false;
          this.staticarrowsVisible = true;
          this.currentSortedColumn = data.column_name;
          this.sortedColumn = data.column_name;
          if (data.sort_method === 'desc') {
            this.iconVisibleDesc = true;
            this.iconVisibleAsc = false;
          }
          if (data.sort_method === 'asc') {
            this.iconVisibleAsc = true;
            this.iconVisibleDesc = false;
          }
          this.sortfield = response.candidate_statuses.sort_field;
          this.sortmethod = response.candidate_statuses.sort_method;
        }
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  mouseEnter(columnname: any) {
    this.iconVisible = true;
    this.currentSortedColumn = columnname;
    this.mouseOnColumnName = columnname;
  }

  mouseLeave(columnname: any) {
    this.iconVisible = false;
    this.staticarrowsVisible = true;
    this.mouseOnColumnName = '';
  }

  openDeleteModal(content: any, deleteId: number, value: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    this.deleteCandidateStatusId = deleteId;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  deleteCandidateStatus1() {
    let deleteId = this.deleteCandidateStatusId;
    this.candidateStatusService.deleteCandidateStatus(deleteId).subscribe({
      next: (response) => {
        this.notificationService.showSuccess(response.message);
        this.getCandidateStatusList({});
        this.modalService.dismissAll();
      },
      error: (error) => {
        this.notificationService.showError(error.error.message);
      },
    });
  }

  openUpdateModal(content: any, updateId: number, value: any) {
    this.candidateStatusService.getCandidateStatusDetail(updateId).subscribe({
      next: (response: any) => {
        let candidateStatusDetail = response.candidate_status;
        this.updateCandidateStatusForm['name'].setValue(
          candidateStatusDetail.name
        );
        this.updateCandidateStatusForm['description'].setValue(
          candidateStatusDetail.description
        );
        this.updateCandidateStatusForm['sequence'].setValue(
          candidateStatusDetail.sequence
        );
        this.updateCandidateStatusForm['updateId'].setValue(updateId);
        this.updateCandidateStatusForm['status'].setValue(
          candidateStatusDetail.is_active.toString()
        );
        this.modalService
          .open(content, { ariaLabelledBy: 'modal-basic-title' })
          .result.then(
            (result) => {
              this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          );
      },
    });
  }

  setPage(page: number) {
    this.pageNumber = page;
    this.getCandidateStatusList({
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
    });
  }

  showfilterimage(data: string, strengthId: any) {
    this.imagepath = data;
    this.imageselected = true;
    this.filterStrengthId = strengthId;
  }

  resetBackendCSerror() {
    this.updateCSErrorName = '';
    this.updateCSErrorDescription = '';
    this.updateCSError = '';
    this.UpdateCandidateStatusForm.reset();
  }

  updateCandidateStatus() {
    this.submitted = true;
    if (this.UpdateCandidateStatusForm.invalid) {
      return;
    }
    let id = this.updateCandidateStatusForm['updateId'].value;
    this.updateCandidateStatusData = {
      name: this.updateCandidateStatusForm['name'].value,
      description: this.updateCandidateStatusForm['description'].value,
      sequence: this.updateCandidateStatusForm['sequence'].value,
      is_active: this.updateCandidateStatusForm['status'].value,
    };
    this.csapicall = true;
    this.candidateStatusService
      .updateCandidateStatus(id, this.updateCandidateStatusData)
      .subscribe({
        next: (response) => {
          this.modalService.dismissAll();
          this.UpdateCandidateStatusForm.reset();
          this.notificationService.showSuccess(response.message);

          this.csapicall = false;
          this.submitted = false;
          this.getCandidateStatusList({});
        },
        error: (error) => {
          this.updateCSError = error.error.message;
          this.csapicall = false;
          if (error.status === 422) {
            if (error.error.name) {
              this.updateCSErrorName = error.error.name[0];
            }
            if (error.error.sequence) {
              this.updateCSErrorSequence = error.error.sequence[0];
            }
            if (error.error.description) {
              this.updateCSErrorDescription = error.error.description[0];
            }
          }
        },
      });
  }
}
