import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/login/pages';
import { HomeComponent } from './modules/dashboard/pages/home/home.component';
import { CandidateComponent } from './modules/candidate/pages/candidate/candidate.component';
import { CandidateListComponent } from './modules/candidate/pages/candidate-list/candidate-list.component';
import { authGuard } from './core/guards/auth.guard';
import { ProjectListComponent } from './modules/projects/pages/project-list/project-list.component';
import { UserListComponent } from './modules/users/pages/user-list/user-list.component';
import { AdminComponent } from './modules/admin/pages/admin/admin.component';
import { ClientListComponent } from './modules/client/pages/client-list/client-list.component';
import { TagsListComponent } from './modules/tags/pages/tags-list/tags-list.component';
import { MainLayoutComponent } from './core/layout/main-layout/main-layout/main-layout.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { CandidateModule } from './modules/candidate/candidate.module';
import { ProjectsModule } from './modules/projects/projects.module';
import { ClientModule } from './modules/client/client.module';
import { TagsModule } from './modules/tags/tags.module';
import { UsersModule } from './modules/users/users.module';
import { LoginModule } from './modules/login/login.module';
import { TenantsModule } from './modules/tenants/tenants.module';
import { CandidateStatusModule } from './modules/candidate-status/candidate-status.module';
import { ClientStatusModule } from './modules/client-status/client-status.module';
import { RegisterComponent } from './modules/login/pages/register/register.component';
import { JobsModule } from './modules/jobs/jobs.module';
import { PublicJoblistComponent } from './modules/jobs/public-joblist/public-joblist.component';
import { JoblistComponent } from './modules/jobs/joblist/joblist.component';
import { AppliedjobsComponent } from './modules/jobs/appliedjobs/appliedjobs.component';
import { CandidateLoginComponent } from './modules/login/pages/candidate-login/candidate-login.component';
import { EmailVerificationComponent } from './modules/login/pages/email-verification/email-verification.component';


const routes: Routes = [

  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'register', component: RegisterComponent, },
  { path: 'jobs', component: CandidateLoginComponent, },
  { path: 'email-verification', component: EmailVerificationComponent, },


  // { path: 'public-joblist', component: PublicJoblistComponent, },
  // { path: 'public-joblist/:tenant', component: PublicJoblistComponent },
  { path: ':tenant/public', component: PublicJoblistComponent },

  {
    path: '', component: MainLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => DashboardModule
      },
      {
        path: 'candidate',
        loadChildren: () => CandidateModule
      },
      {
        path: 'candidate-list',
        loadChildren: () => CandidateModule
      },
      {
        path: 'candidate-status',
        loadChildren: () => CandidateStatusModule
      },
      {
        path: 'client-status',
        loadChildren: () => ClientStatusModule
      },
      {
        path: 'search-list',
        loadChildren: () => ProjectsModule
      },
      {
        path: 'search',
        loadChildren: () => ProjectsModule
      },
      {
        path: 'user-list',
        loadChildren: () => UsersModule
      },
      {
        path: 'admin',
        loadChildren: () => AdminComponent
      },
      {
        path: 'client',
        loadChildren: () => ClientModule
      },
      {
        path: 'client-list',
        loadChildren: () => ClientModule
      },
      {
        path: 'tags-list',
        loadChildren: () => TagsModule
      },
      {
        path: 'tenants-list',
        loadChildren: () => TenantsModule
      },

    ],
    canActivate: [authGuard]
  },


  {
    path: 'candidate-profile',
    loadChildren: () => JobsModule,
    canActivate: [authGuard]
  },

  {
    path: 'jobslist',
    component: JoblistComponent,
    canActivate: [authGuard]
  },

  {
    path: 'applied-jobs/:tenant_id',
    component: AppliedjobsComponent,
    canActivate: [authGuard]
  },

  {
    path: 'reset-password',
    loadChildren: () => LoginModule
  },


  /*{

     {
        path: 'candidate-profile',
        loadChildren: () => JobsModule
      }
    path: 'candidate/:id',
    component: CandidateComponent,
    pathMatch: 'full',
    canActivate: [authGuard]
  }, {
    path: 'candidate-list',
    component: CandidateListComponent,
    pathMatch: 'full',
    canActivate: [authGuard]
  },{
    path: 'search-list',
    component: ProjectListComponent,
    pathMatch: 'full',
    canActivate: [authGuard]
  },{
    path: 'user-list',
    component: UserListComponent,
    pathMatch: 'full',
    canActivate: [authGuard]
  },{
    path: 'admin',
    component: AdminComponent,
    pathMatch: 'full',
    canActivate: [authGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'client-list',
    component: ClientListComponent,
    pathMatch: 'full'
  },
  {
    path: 'tags-list',
    component: TagsListComponent,
    pathMatch: 'full'
  }, */

  //Fallback when no prior routes is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
