import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ClientRoutingModule } from './client-routing.module';
import { ClientListComponent } from './pages/client-list/client-list.component';
import { ClientComponent } from './pages/client/client.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    ClientComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClientRoutingModule,
    NgSelectModule,
    NgbTooltip
  ]
})
export class ClientModule { }
