import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { CandidateService } from 'src/app/core/services/candidates/candidate.service';
import { ClientService } from 'src/app/core/services/clients/client.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TagserviceService } from 'src/app/core/services/tags/tagservice.service';
import { ProjectService } from 'src/app/core/services/projects/project.service';
import { NotesService } from 'src/app/core/services/notes/notes.service';
import {
  faArrowUpRightFromSquare,
  faCompress,
  faShare,
} from '@fortawesome/free-solid-svg-icons';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Location } from '@angular/common';
import {
  StorageKey,
  StorageService,
} from 'src/app/core/common/storage.service';
import {
  futureDateValidator,
  endDateValidator,
} from '../../../candidate/custom-validators';
import {
  dueDateNotPast,
  birthDateNotFuture,
} from '../../../../shared/components/date.validators';

import { UserService } from 'src/app/core/services/users/user.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css'],
})
export class ClientComponent implements OnInit {
  tooltipContent: string = `You cannot change this as it is a candidate's login ID.`;
  faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  faCompress = faCompress;
  faShare = faShare;
  CreateCandidateForm!: FormGroup;
  CreateNoteForm!: FormGroup;
  createNoteError: any;
  updateNoteContactId: any;
  CreateCandidateDetailsForm!: FormGroup;
  CreateContactDetailsForm!: FormGroup;
  AddDocumentForm!: FormGroup;
  UpdateNoteForm!: FormGroup;
  submitted: boolean = false;
  updateCandidateId: any;
  updateContactId: any;
  closeResult: string = '';
  tagList = [];
  files: any;
  refreshDate: any;
  deleteDocumentId: any;
  projectData: any = [];
  projectUpdate: any;
  uploadedDocuments: any;
  showTagDropdown = false;
  selectedtagValue: any;
  noteId: any;
  ClientId!: number;
  candidateStatus: any = [];
  projectRank: any;
  projectStatus: any;
  selectedProjectName: string = '';
  createCandidateData: any;
  isSearchable: boolean = false;
  searchClicked: string = '';
  selectedProject: any;
  projectLists: any = [];
  deleteProjectId: any;
  stateData: any = [];
  languageData = [];
  // selectedlanguage: any
  selectedlanguage: any[] = [];
  candidatetagList: any;
  allowExportcheck: any;
  strengthId: any;
  documentData: any;
  deleteName: any;
  pageTitle: string = 'Candidate Detail';
  showCreateCandidate: boolean = false;
  showProjectCandidate: boolean = false;
  showCreateButton: string = '';
  clientData: any;
  pageNumber: number = 1;
  pageSize: number = 10;
  totalRows: number = 0;
  pager: any = {};
  fileName!: string;
  selectedProjectStatus: any;
  imageselected: boolean = false;
  imagepath: string = '';
  filePath!: string;
  notesList: any = [];
  deleteNoteId: any;
  editNoteSearchName: any = '';
  updatesubmitted: boolean = false;
  tagError: boolean = false;
  selectedStrengths: any = {};
  projectapicall: boolean = false;
  isInputEnabled: boolean = true;
  @ViewChild('tagselect', { static: false }) tagselect?: NgSelectComponent;
  CreateCandidateDetailsError: any;
  CreateCandidateDetailsErrorFirstname: any;
  CreateCandidateDetailsErrorLastname: any;
  CreateCandidateDetailsErrorGender: any;
  CreateCandidateDetailsErrorInbirthdate: any;
  CreateCandidateDetailsErrorCurrentcompany: any;
  CreateCandidateDetailsErrorCurrentposition: any;
  CreateCandidateDetailsErrorCustomdiversity: any;
  CreateCandidateDetailsErrorCustomcompensationdetails: any;
  CreateCandidateDetailsErrorLanguageids: any;
  CreateCandidateDetailsErrorLinkedinurl: any;
  CreateContactsDetailsError: any;
  CreateContactsDetailsErrorInpersonalphone: any;
  CreateContactsDetailsErrorInpersonalemail: any;
  CreateContactsDetailsErrorInworkphone: any;
  CreateContactsDetailsErrorInworkemail: any;
  CreateContactsDetailsErrorisInpersonalphonepreffered: any;
  CreateContactsDetailsErrorisInpersonalemailpreffered: any;
  CreateContactsDetailsErrorisInworkphonepreffered: any;
  CreateContactsDetailsErrorisInworkemailpreffered: any;
  CreateContactsDetailsErrorInstate: any;
  CreateContactsDetailsErrorIncity: any;
  CreateContactsDetailsErrorIncountryfullname: any;
  CreatDocumentsError: any;
  CreatDocumentsErrorDocument: any;
  CreatDocumentsErrorDescription: any;
  UpdateDocumentsError: any;
  UpdateDocumentsErrorDocument: any;
  UpdateDocumentsErrorDescription: any;
  CreatNotesError: any;
  CreatNotesErrorProjectid: any;
  CreatNotesErrorNote: any;
  CreatNotesErrorAllowexport: any;
  addContactErrors: any;
  UpdateNotesError: any;
  UpdateNotesErrorNote: any;
  UpdateNotesErrorAllowexport: any;
  createAttachError: any;
  createAttachErrorProjectid: any;
  files2: any;
  fileName2: any;
  filePath2: any;
  url: any;
  url2: any;
  isEmailNotes: boolean = false;
  language_ids: any[] = [];
  updateContactDetailsErrorInCandidate: any;
  updateContactDetailsErrorInName: any;
  // language: void;
  get createCandidateForm() {
    return this.CreateCandidateForm.controls;
  }
  get createNoteForm() {
    return this.CreateNoteForm.controls;
  }
  get updateNoteForm() {
    return this.UpdateNoteForm.controls;
  }
  @ViewChild('formDirective')
  Probability: any;
  clientsData: any = [];
  ClientStatuslist: any = [];
  ClientProbabiltylist: any = [];
  clientsList = [];
  selectedClientId: any;
  createClientAttachError: any;
  is_already_linked: any;
  @ViewChild('replaceClient') replaceClient: any;
  deleteClientId: any;
  is_client_module_access_disabled: any;
  isself_created: any;
  clientNotesList: any = [];

  deleteContactId: any;

  emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  @ViewChild('ViewClientModal', { static: false }) ViewClientModal: any;
  CreateClientForm!: FormGroup;
  createContactForm!: FormGroup;
  updateContactForm!: FormGroup;
  ph3 = /[- +()0-9]+/;

  rectangleBox: boolean = true;
  logoBox: boolean = false;
  logoBox2: boolean = false;
  openLogoSection: boolean = false;
  CreatDocumentsErrorDocuments: any;
  createClientError: string = '';
  createClientErrorName: any;
  createClientErrorIndustry: any;
  createClientErrorContactnumber: any;
  createClientErrorEmail: any;
  createClientErrorAddress: any;
  createClientErrorPostal: any;
  createClientErrorCity: any;
  createClientErrorState: any;
  createClientErrorWebsite: any;
  createClientErrorDescription: any;
  createClientErrorPrimarycontact: any;
  showexternallink1: boolean = false;
  showexternallink2: boolean = false;
  showexternallink3: boolean = false;
  editexternallink1: boolean = false;
  editexternallink2: boolean = false;
  editexternallink3: boolean = false;
  weblink1: string = '';
  weblink2: string = '';
  weblink3: string = '';

  userList: any;
  clientStatusList: any = [];
  clientStage: string = '';
  clientProbability: string = '';
  static_primary_contact: boolean = true;
  candidateList: any = [];
  stateList: any;

  addCandidateId: any;

  projectapicall2: boolean = false;

  showUploadFileButton: boolean = false;
  CreatDocumentsErrorDocumentsname: any;
  createClientErrorLogo: any;
  createClientErrorWeblinkthree: any;
  createClientErrorWeblinkone: any;
  createClientErrorWeblinktwo: any;
  clientList: any = [];
  uploadsubmitted: boolean = false;

  strengths = [
    { label: 'Select strength', value: 0 },
    { label: '1 Star', value: 1 },
    { label: '2 Stars', value: 2 },
    { label: '3 Stars', value: 3 },
    { label: '4 Stars', value: 4 },
    { label: '5 Stars', value: 5 },
  ];

  isResumeOptions = [
    { label: 'Yes', value: '1' },
    { label: 'No', value: '0' },
  ];

  // Get an array of stars based on the value
  getStars(value: number): string[] {
    return new Array(value).fill('★');
  }

  private formDirective!: NgForm;
  constructor(
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private candidateService: CandidateService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private tagService: TagserviceService,
    private projectService: ProjectService,
    private notesService: NotesService,
    private router: Router,
    private location: Location,
    private storageService: StorageService,
    private ClientService: ClientService,
    private clientService: ClientService,
    private userService: UserService
  ) {
    this.is_client_module_access_disabled = this.storageService.getValue(
      'is_client_module_access_disabled'
    );

    this.CreateClientForm = this.formBuilder.group({
      ClientId: [null],
      name: [null, [Validators.required]],
      user_id: [null, [Validators.required]],
      industry: [null],
      city: [null],
      state: [null],
      // contact_email: [null, [Validators.pattern(this.emailPattern)]],
      //contact_number:[null,[Validators.pattern("[0-9 ]{12}")]],
      // contact_number: [null, [Validators.pattern(this.ph3)]],
      postal_code: [null],
      street_address: [null],
      description: [null],
      website: [null, [Validators.pattern(this.getURLPattern())]],
      // primary_contact: [null],
      // primary_contact_static: [null],
      client_status_id: [0],
      client_probability_status_id: [0],
      no_of_searches: [null],
      web_link_one: [null, [Validators.pattern(this.getURLPattern())]],
      web_link_two: [null, [Validators.pattern(this.getURLPattern())]],
      web_link_three: [null, [Validators.pattern(this.getURLPattern())]],
      // web_link_one:[null,[this.validateUrl]],
      document: [null],
      logo: [null],
      // candidate_id: [null],
      is_referenceable: [null],
    });

    this.createContactForm = this.formBuilder.group({
      primary_contact: [null],
      primary_contact_static: [null],
      contact_number: [null, [Validators.pattern(this.ph3)]],
      contact_email: [null, [Validators.pattern(this.emailPattern)]],
    });

    this.updateContactForm = this.formBuilder.group({
      primary_contact: [null],
      primary_contact_static: [null],
      contact_number: [null, [Validators.pattern('[0-9 ]{12}')]],
      contact_email: [null, [Validators.pattern(this.emailPattern)]],
    });
  }

  ngOnInit(): void {
    this.ClientId = this.route.snapshot.params['id'];
    this.getDocumentData();
    this.onLoad();
    this.getProjectList();
    this.getCandidateStatus();
    this.getClientNotes();
    // this.getclientStatus();
    // this.getclientprobability();
    this.getClientsList();
    // this.getClientStatuses();
    this.getUsersList();
  }
  get f() {
    return this.createContactForm.controls;
  }
  get client() {
    return this.CreateClientForm.controls;
  }

  createCandidateform() {
    this.CreateCandidateDetailsForm = this.formBuilder.group({
      id: [null],
      firstname: [
        null,
        [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(15),
        ],
      ],
      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(15),
        ],
      ],
      gender: [null],
      strength: [null],
      current_company: [null],
      current_position: [null],
      // in_birth_date: [null],
      in_birth_date: [null, [birthDateNotFuture()]],
      in_state: [null],
      in_city: [null],
      in_work_phone: [null],
      // in_personal_email: [null],
      in_personal_email: [null, [Validators.pattern(this.emailPattern)]],
      in_personal_phone: [null],
      in_work_email: [null, [Validators.pattern(this.emailPattern)]],
      country: [null],
      is_in_personal_phone_preffered: [null],
      is_in_personal_email_preffered: [null],
      is_in_work_phone_preffered: [null],
      is_in_work_email_preffered: [null],
      language_ids: [null],
      // linkedin_url:[{value:"",disabled:true}],
      linkedin_url: [null],
      custom_diversity: [null],
      custom_compensation_details: [null],
      source: [null],
    });
  }

  getClientsList() {
    this.clientService.listAll().subscribe({
      next: (response) => {
        this.clientsList = response.clients.data;
        this.clientsList = this.clientsList.filter(
          (ele: any) => ele.is_active == 1
        );
      },
      error: (error) => {},
    });
  }

  onClientSearch(term: any) {
    let searchValue = term.term;
    if (true) {
      let data = { search: searchValue };
      this.clientService.searchClientwiseList(data).subscribe({
        next: (response: any) => {
          this.clientsList = response.clients.data;
          this.clientsList = this.clientsList.filter(
            (ele: any) => ele.is_active == 1
          );
        },
        error: (err: any) => {},
      });
    }
  }

  stripHtmlTags(html: string): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  // Delete Client
  deleteClient() {
    this.clientService
      .deleteclient(this.ClientId, this.deleteClientId)
      .subscribe({
        next: (response: any) => {
          this.notificationService.showSuccess(response.message);
          this.onLoad();
          this.modalService.dismissAll();
        },
        error: (error: any) => {},
      });
  }

  showclient(clientid: number) {
    const client = this.clientData;
    this.ClientId = client.id;
    this.getCandidatePhoneNumber();
    this.getDocumentData();
    this.getStateList();
    this.getUsersList({ term: client.owner?.name });
    this.url2 = client.logo_path;
    this.rectangleBox = true;
    this.openLogoSection = false;
    if (this.url2 == null) {
      this.rectangleBox = true;
      this.logoBox2 = false;
    } else if (this.url2 != null) {
      this.rectangleBox = false;
      this.logoBox2 = true;
    }

    if (client.web_link_one !== 'null' && client.web_link_one != null) {
      this.CreateClientForm.controls['web_link_one'].setValue(
        client.web_link_one
      );
      this.showexternallink1 = true;
      this.weblink1 = client.web_link_one;
      this.editexternallink1 = false;
    } else {
      this.CreateClientForm.controls['web_link_one'].setValue('');
      this.showexternallink1 = false;
      this.weblink1 = '';
      this.editexternallink1 = true;
    }

    if (client.website !== 'null' && client.website != null) {
      this.CreateClientForm.controls['website'].setValue(client.website);
    } else {
      this.CreateClientForm.controls['website'].setValue('');
    }

    if (client.web_link_two !== 'null' && client.web_link_two != null) {
      this.CreateClientForm.controls['web_link_two'].setValue(
        client.web_link_two
      );
      this.showexternallink2 = true;
      this.weblink2 = client.web_link_two;
      this.editexternallink2 = false;
    } else {
      this.CreateClientForm.controls['web_link_two'].setValue('');
      this.showexternallink2 = false;
      this.weblink2 = '';
      this.editexternallink2 = true;
    }

    if (client.web_link_three !== 'null' && client.web_link_three != null) {
      this.CreateClientForm.controls['web_link_three'].setValue(
        client.web_link_three
      );
      this.showexternallink3 = true;
      this.weblink3 = client.web_link_three;
      this.editexternallink3 = false;
    } else {
      this.CreateClientForm.controls['web_link_three'].setValue('');
      this.showexternallink3 = false;
      this.weblink3 = '';
      this.editexternallink3 = true;
    }

    if (client.description !== 'null' && client.description != null) {
      this.CreateClientForm.controls['description'].setValue(
        client.description
      );
    } else {
      this.CreateClientForm.controls['description'].setValue('');
    }

    if (client.name == null) {
      this.CreateClientForm.controls['name'].setValue(null);
    } else {
      this.CreateClientForm.controls['name'].setValue(client.name);
    }

    if (client.owner == null) {
      this.CreateClientForm.controls['user_id'].setValue(null);
    } else {
      this.CreateClientForm.controls['user_id'].setValue(client.owner.id);
    }

    if (client.industry !== 'null' && client.industry != null) {
      this.CreateClientForm.controls['industry'].setValue(client.industry);
    } else {
      this.CreateClientForm.controls['industry'].setValue('');
    }

    if (client.projects_count !== 'null' && client.projects_count != null) {
      this.CreateClientForm.controls['no_of_searches'].setValue(
        client.projects_count
      );
    } else {
      this.CreateClientForm.controls['no_of_searches'].setValue('');
    }
    this.client['no_of_searches'].disable();

    if (client.no !== 'null' && client.client_status_id != 0) {
      this.CreateClientForm.controls['client_status_id'].setValue(
        client.client_status_id
      );
    } else {
      this.CreateClientForm.controls['client_status_id'].setValue(0);
    }

    // Proability staus Id
    if (client.no !== 'null' && client.client_probability_status_id != 0) {
      this.CreateClientForm.controls['client_probability_status_id'].setValue(
        client.client_probability_status_id
      );
    } else {
      this.CreateClientForm.controls['client_probability_status_id'].setValue(
        0
      );
    }

    if (client.street_address !== 'null' && client.street_address != null) {
      this.CreateClientForm.controls['street_address'].setValue(
        client.street_address
      );
    } else {
      this.CreateClientForm.controls['street_address'].setValue('');
    }

    if (client.postal_code !== 'null' && client.postal_code != null) {
      this.CreateClientForm.controls['postal_code'].setValue(
        client.postal_code
      );
    } else {
      this.CreateClientForm.controls['postal_code'].setValue('');
    }

    if (client.city !== 'null' && client.city != null) {
      this.CreateClientForm.controls['city'].setValue(client.city);
    } else {
      this.CreateClientForm.controls['city'].setValue('');
    }

    if (client.state !== 'null' && client.state != null) {
      this.CreateClientForm.controls['state'].setValue(client.state);
    } else {
      this.CreateClientForm.controls['state'].setValue(null);
    }

    // if (client.contact_email !== 'null' && client.contact_email != null) {
    //   this.CreateClientForm.controls['contact_email'].setValue(
    //     client.contact_email
    //   );
    // } else {
    //   this.CreateClientForm.controls['contact_email'].setValue('');
    // }

    // if (client.contact_number !== 'null' && client.contact_number != null) {
    //   this.CreateClientForm.controls['contact_number'].setValue(
    //     client.contact_number
    //   );
    // } else {
    //   this.CreateClientForm.controls['contact_number'].setValue('');
    // }

    // if (client.candidate_id == null) {
    //   this.CreateClientForm.controls['candidate_id'].setValue('');
    // } else {
    //   this.CreateClientForm.controls['candidate_id'].setValue(
    //     client.candidate_id
    //   );
    // }

    // this.CreateClientForm.controls['ClientId'].setValue(client.id);

    // if (client.primary_contact !== 'null' && client.primary_contact != null) {
    //   this.CreateClientForm.controls['primary_contact_static'].setValue(
    //     client.primary_contact
    //   );
    //   this.CreateClientForm.controls['primary_contact'].setValue(
    //     client.primary_contact
    //   );
    //   this.client['primary_contact_static'].disable();
    // } else {
    // }

    this.CreateClientForm.controls['is_referenceable'].setValue(
      client.is_referenceable !== null ? client.is_referenceable : null
    );
  }

  adddocumentForm() {
    this.AddDocumentForm = this.formBuilder.group({
      document: [null, Validators.required],
      //  description:[null],
      description: [null, [Validators.minLength(0), Validators.maxLength(15)]],
      documentId: [null],
      is_resume: ['0', Validators.required],
    });
  }

  onLoad() {
    this.getClientData();
    this.onBuildForm();
  }

  getClientData() {
    this.projectapicall = true;
    this.clientService
      .getClientData({ id: this.route.snapshot.params['id'] })
      .subscribe({
        next: (response: any) => {
          this.projectapicall = false;
          this.clientData = response.client;
          this.getclientStatus();
          this.getCandidatePhoneNumber();
          this.clientData?.contacts.forEach((candidate: any) => {
            candidate.full_name =
              candidate.in_first_name + ' ' + candidate.in_last_name;
          });
        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });
  }

  showProject2(id: number, searchvalue: string = '', projectRank: string = '') {
    this.router.navigate(['/search/' + id + '/' + projectRank]);

    /* if(searchvalue == '')
      this.router.navigate(['/search/' + id]);
    else
      this.router.navigate(['/search/' + id +'/'+searchvalue]); */
  }

  showProject3(id: number) {
    if (!id) {
      return;
    }
    this.router.navigate(['/candidate/' + id]);
  }

  onBuildForm() {
    this.CreateCandidateForm = this.formBuilder.group({
      linkedInUrl: [null, [Validators.required]],
    });

    this.CreateNoteForm = this.formBuilder.group({
      notes_project: [null, [Validators.required]],
      client_notes: [null, [Validators.required]],
      notes: [null, [Validators.required]],
      allow_export: [null],
    });

    this.UpdateNoteForm = this.formBuilder.group({
      notes_id: [null],
      notes_project: [null],
      notes: [null, [Validators.required]],
      allow_export: [null],
    });
  }

  getProjectList() {
    this.projectService.listAll().subscribe({
      next: (response) => {
        this.projectLists = response.projects.data;
        this.projectLists = this.projectLists.filter(
          (ele: any) => ele.project_status_id == 1
        );
        this.projectLists.forEach((ele: any) => {
          ele['dropdown'] =
            ele?.name +
            (ele?.client?.name ? ' [' + ele.client?.name + ']' : '');
        });
      },
      error: (err) => {},
    });
  }

  resetBackendAddNoteerror() {
    this.CreatNotesErrorProjectid = '';
    this.CreatNotesErrorNote = '';
    this.CreatNotesErrorAllowexport = '';
    this.CreatNotesError = '';
    this.createNoteError = '';
    this.updateContactDetailsErrorInCandidate = '';
    this.updateContactDetailsErrorInName = '';
    this.addContactErrors = {};
    this.CreateNoteForm.reset();
  }

  addContact() {
    if (!this.createContactForm.valid) {
      return;
    }
    let data = {};
    if (this.addCandidateId) {
      data = {
        name: this.createContactForm.controls['primary_contact_static'].value,
        phone: this.createContactForm.controls['contact_number'].value,
        email: this.createContactForm.controls['contact_email'].value,
        candidate_id: this.addCandidateId,
      };
    } else {
      data = {
        name: this.createContactForm.controls['primary_contact_static'].value,
        phone: this.createContactForm.controls['contact_number'].value,
        email: this.createContactForm.controls['contact_email'].value,
      };
    }
    this.clientService.addContact(this.ClientId, data).subscribe({
      next: (response) => {
        this.f['primary_contact_static'].enable();
        this.modalService.dismissAll();
        this.getClientData();
        this.createContactForm.reset();
        this.addCandidateId = '';
      },
      error: (err) => {
        this.projectapicall = false;
        this.addContactErrors = err.error;
      },
    });
  }

  getContactName(contact_id: any, candidate_id: any) {
    let data;
    if (contact_id) {
      data = this.clientData?.contacts.find(
        (data: any) => data.id === contact_id
      );
    } else {
      data = this.clientData?.contacts.find(
        (data: any) => data.id === candidate_id
      );
    }

    if (data) {
      if (data.candidate_id) {
        return (
          data.candidate?.in_first_name + ' ' + data.candidate?.in_last_name
        );
      } else {
        return data?.name;
      }
    }
  }

  isContactCandidate(contact_id: any) {
    const data = this.clientData?.contacts.find(
      (data: any) => data.id === contact_id
    );
    if (data) {
      if (data.candidate_id) {
        return 1;
      } else {
        return 0;
      }
    }
    return 0;
  }

  updateContactDetails() {
    let data = {};
    if (this.addCandidateId) {
      data = {
        name: this.updateContactForm.controls['primary_contact_static'].value,
        phone: this.updateContactForm.controls['contact_number'].value,
        email: this.updateContactForm.controls['contact_email'].value,
        candidate_id: this.addCandidateId,
      };
    } else {
      data = {
        name: this.updateContactForm.controls['primary_contact_static'].value,
        phone: this.updateContactForm.controls['contact_number'].value,
        email: this.updateContactForm.controls['contact_email'].value,
      };
    }
    this.clientService
      .updateContact(this.ClientId, this.updateContactId, data)
      .subscribe({
        next: (response) => {
          window.location.reload();
          this.notificationService.showSuccess(response.message);
          this.getClientNotes();
          this.modalService.dismissAll();
          this.getClientData();
          this.updateContactForm.reset();
        },
        error: (error) => {
          if (error.error.candidate_id) {
            this.updateContactDetailsErrorInCandidate =
              error.error.candidate_id[0];
          }
          if (error.error.name) {
            this.updateContactDetailsErrorInName = error.error.name[0];
          }
        },
      });
  }

  addNote() {
    this.submitted = true;
    let data = {
      client_id: this.ClientId,
      contact_id: this.CreateNoteForm.controls['client_notes'].value,
      note: this.CreateNoteForm.controls['notes'].value,
      note_type: 'contact',
    };
    if (!data.contact_id) {
      this.createNoteError = 'Contact field is required';
      return;
    }

    this.projectapicall = true;
    this.notesService.createContactNote(data).subscribe({
      next: (response) => {
        window.location.reload();
        this.getDocumentData();
        this.notificationService.showSuccess(response.message);
        this.modalService.dismissAll();
        this.CreateNoteForm.reset();
        this.submitted = false;
        this.projectapicall = false;
        this.getClientNotes();
      },
      error: (error) => {
        this.projectapicall = false;
        this.CreatNotesError = error.error.message;
        if (error.status === 422) {
          if (error.error.project_id) {
            this.CreatNotesErrorProjectid = error.error.project_id[0];
          }
          if (error.error.note) {
            this.CreatNotesErrorNote = error.error.note[0];
          }
          if (error.error.allow_export) {
            this.CreatNotesErrorAllowexport = error.error.allow_export[0];
          }
        }
      },
    });
  }

  populateAddNotes(notes_project: any, notes: any, allow_export: any) {
    this.CreateNoteForm.controls['notes_project'].setValue(notes_project);
    this.CreateNoteForm.controls['notes'].setValue(notes);
    this.CreateNoteForm.controls['allow_export'].setValue(allow_export ? 1 : 0);
    this.CreateNoteForm.controls['notes_project'].setValue(0);
  }

  getStates() {
    this.candidateService.getstate().subscribe({
      next: (response: any) => {
        this.stateData = response.states;
      },
      error: (error: any) => {},
    });
  }
  getlanguage() {
    this.candidateService.getlanguageData().subscribe({
      next: (response: any) => {
        this.languageData = response.languages;
      },
      error: (error) => {},
    });
  }

  attachFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;
  }

  restrictAlphabet(evt: any) {
    const keys = evt.keyCode;
    if (keys < 48 || keys > 58) {
      return false;
    } else {
      return true;
    }
  }

  resetBackendUploadDocumenterror() {
    this.CreatDocumentsErrorDocument = '';
    this.CreatDocumentsErrorDescription = '';
    this.CreatDocumentsError = '';
  }

  UploadDocuments() {
    this.submitted = true;
    if (!this.files) {
      return;
    }

    if (this.AddDocumentForm.invalid) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('name', this.fileName);
    formData.append('document', this.files);
    formData.append(
      'description',
      this.AddDocumentForm.controls['description'].value
    );
    formData.append(
      'is_resume',
      this.AddDocumentForm.controls['is_resume'].value
    );

    this.projectapicall = true;
    this.candidateService.createDocument(this.ClientId, formData).subscribe({
      next: (response: any) => {
        // this.onLoad();
        this.modalService.dismissAll();
        this.notificationService.showSuccess(response.message);
        this.projectapicall = false;
        this.submitted = false;
        this.onLoad();
      },
      error: (error) => {
        this.projectapicall = false;
        this.CreatDocumentsError = error.error.message;
        if (error.status === 422) {
          if (error.error.document) {
            this.CreatDocumentsErrorDocument = error.error.document[0];
          }
          if (error.error.description) {
            this.CreatDocumentsErrorDescription = error.error.description[0];
          }
        }
      },
    });
  }
  EditDocument(EditId: any) {
    let document = this.documentData.filter((ele: any) => ele.id == EditId);
    if (typeof document[0].description === 'string') {
      document[0].description = JSON.parse(document[0].description);
    }
    this.AddDocumentForm.controls['description'].setValue(
      document[0].description
    );
    this.AddDocumentForm.controls['is_resume'].setValue(
      document[0].is_resume.toString()
    );
    this.AddDocumentForm.controls['documentId'].setValue(document[0].id);
  }
  UpdateDocuments() {
    this.submitted = true;

    if (!this.files) {
      return;
    }

    if (this.AddDocumentForm.invalid) {
      return;
    }

    let documentId = this.AddDocumentForm.controls['documentId'].value;

    const formData: FormData = new FormData();
    formData.append('name', this.fileName);
    formData.append('document', this.files);
    formData.append(
      'description',
      this.AddDocumentForm.controls['description'].value
    );
    formData.append(
      'is_resume',
      this.AddDocumentForm.controls['is_resume'].value
    );
    this.projectapicall = true;
    this.candidateService
      .updateDocument(this.ClientId, documentId, formData)
      .subscribe({
        next: (response: any) => {
          this.notificationService.showSuccess(response.message);
          window.location.reload();
          this.projectapicall = false;
          this.submitted = false;
        },
        error: (error) => {
          this.projectapicall = false;
          this.UpdateDocumentsError = error.error.message;
          if (error.status === 422) {
            if (error.error.document) {
              this.UpdateDocumentsErrorDocument = error.error.document[0];
            }
            if (error.error.description) {
              this.UpdateDocumentsErrorDescription = error.error.description[0];
            }
          }
        },
      });
  }

  deleteDocuments() {
    this.candidateService
      .deleteDocument(this.ClientId, this.deleteDocumentId)
      .subscribe({
        next: (response: any) => {
          this.notificationService.showSuccess(response.message);
          window.location.reload();
        },
        error: (err: any) => {},
      });
  }
  removeFile() {}
  clearTags(deleteId: any) {
    this.candidateService.dettachtag(this.ClientId, deleteId).subscribe({
      next: (response) => {
        this.onLoad();
      },
      error: (err) => {},
    });
  }

  OpenCandidateDetails() {
    this.CreateCandidateDetailsForm.controls['id'].setValue(this.clientData.id);
    this.CreateCandidateDetailsForm.controls['firstname'].setValue(
      this.clientData.in_first_name
    );
    this.CreateCandidateDetailsForm.controls['lastname'].setValue(
      this.clientData.in_last_name
    );

    if (
      this.clientData.in_gender !== null &&
      this.clientData.in_gender !== undefined
    ) {
      const genderValue =
        this.clientData.in_gender.toLowerCase() === 'male'
          ? 'Male'
          : this.clientData.in_gender.toLowerCase() === 'female'
          ? 'Female'
          : 'Other';
      this.CreateCandidateDetailsForm.controls['gender'].setValue(genderValue);
    }
    this.CreateCandidateDetailsForm.controls['in_birth_date'].setValue(
      this.clientData.in_birth_date
    );
    this.CreateCandidateDetailsForm.controls['current_company'].setValue(
      this.clientData.current_company
    );
    this.CreateCandidateDetailsForm.controls['strength'].setValue(
      this.clientData.strength
    );
    this.CreateCandidateDetailsForm.controls['current_position'].setValue(
      this.clientData.current_position
    );
    this.CreateCandidateDetailsForm.controls['linkedin_url'].setValue(
      this.clientData.linkedin_url
    );
    // this.CreateCandidateDetailsForm.controls['language_ids'].setValue(this.clientData.languages);
    this.CreateCandidateDetailsForm.controls['language_ids'].setValue(
      this.clientData.languages.map((a: any) => a.id)
    );
    // console.log(this.CreateCandidateDetailsForm.controls['language_ids'].value);
    // this.language_ids = this.clientData.languages.map((a: any) => a.id);
    // console.log(this.language_ids);
    // this.selectedlanguage = this.clientData.languages
    // console.log(this.selectedlanguage);
    // const languageIds = this.selectedlanguage.map((language: any) => language.id);
    // console.log("languageIds : ", languageIds);
    // this.CreateCandidateDetailsForm.controls['language_ids'].setValue(languageIds);

    this.CreateCandidateDetailsForm.controls['custom_diversity'].setValue(
      this.clientData.custom_diversity
    );
    this.CreateCandidateDetailsForm.controls[
      'custom_compensation_details'
    ].setValue(this.clientData.custom_compensation_details);
    this.CreateCandidateDetailsForm.controls['source'].setValue(
      this.clientData.source
    );
  }

  comparelanguage(lang1: number, lang2: number): boolean {
    return lang1 === lang2;
  }

  projectchange(projectId: any, changetype: any, selectedStatus?: any) {
    switch (changetype) {
      case 'projectRank':
        let inputValue = (<HTMLInputElement>(
          document.getElementById('projectRank_' + projectId)
        )).value;
        this.projectRank = Number(inputValue);
        this.projectUpdate = {
          project_id: projectId,
          rank: this.projectRank,
        };

        break;
      case 'projectStatus':
        this.projectUpdate = {
          project_id: projectId,
          candidate_status_id: selectedStatus == 'null' ? null : selectedStatus,
        };

        break;
      case 'project_Strength':
        this.projectUpdate = {
          project_id: projectId,
          strength: selectedStatus == 'null' ? null : selectedStatus,
        };

        break;
      case 'allow_export':
        this.projectUpdate = {
          project_id: projectId,
          allow_export: this.allowExportcheck,
        };

        break;
    }
    // this.projectRank
    this.candidateService
      .updateProjectStatus(this.ClientId, this.projectUpdate)
      .subscribe({
        next: (response: any) => {
          this.onLoad();
        },
        error: (err: any) => {},
      });
  }

  ClientStatusChange(name: any, changetype: any, client_status_id?: number) {
    const clientToUpdate = this.clientsData.find(
      (client: any) => client.name === name
    );
    if (clientToUpdate) {
      clientToUpdate.client_status_id = client_status_id;
      this.ClientService.updateClientData(
        clientToUpdate.id,
        clientToUpdate
      ).subscribe({
        next: (response) => {
          this.notificationService.showSuccess(response.message);
        },
        error: (error) => {
          console.error('Error updating client data:', error);
          this.notificationService.showError('Failed to update client data.');
        },
      });
    } else {
      console.warn('Client not found in clientsData array');
    }
  }

  Probabilitychange(name: any, changetype: any, probabilityid?: any) {
    const clientToUpdate = this.clientsData.find(
      (client: any) => client.name === name
    );
    if (clientToUpdate) {
      clientToUpdate.client_probability_status_id = probabilityid;
      this.ClientService.updateClientData(
        clientToUpdate.id,
        clientToUpdate
      ).subscribe({
        next: (response) => {
          this.notificationService.showSuccess(response.message);
        },
        error: (error) => {
          console.error('Error updating client data:', error);
          this.notificationService.showError('Failed to update client data.');
        },
      });
    } else {
      console.warn('Client not found in clientsData array');
    }
  }

  showimage(data: string, strengthId: any, projectId: any, changetype: any) {
    this.imagepath = data;
    this.imageselected = true;
    this.strengthId = strengthId;
    this.projectchange(projectId, changetype);
  }
  allowExportchecked(event: any, projectId: any, changetype: any) {
    event.target.checked
      ? (this.allowExportcheck = 1)
      : (this.allowExportcheck = 0);
    this.projectchange(projectId, changetype);
  }
  DeleteCandidates() {
    this.candidateService.deleteCandidateData(this.ClientId).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.notificationService.showSuccess(response.message);
        this.router.navigate(['/candidate']);
      },
      error: (err) => {},
    });
  }

  OpenContactDetails() {
    this.getStates();
    this.CreateCandidateDetailsForm.controls['in_personal_phone'].setValue(
      this.clientData.in_personal_phone
    );
    this.CreateCandidateDetailsForm.controls['in_personal_email'].setValue(
      this.clientData.in_personal_email
    );
    this.CreateCandidateDetailsForm.controls['in_work_phone'].setValue(
      this.clientData.in_work_phone
    );
    this.CreateCandidateDetailsForm.controls['in_work_email'].setValue(
      this.clientData.in_work_email
    );
    this.CreateCandidateDetailsForm.controls[
      'is_in_personal_phone_preffered'
    ].setValue(this.clientData.is_in_personal_phone_preffered);
    this.CreateCandidateDetailsForm.controls[
      'is_in_personal_email_preffered'
    ].setValue(this.clientData.is_in_personal_email_preffered);
    this.CreateCandidateDetailsForm.controls[
      'is_in_work_phone_preffered'
    ].setValue(this.clientData.is_in_work_phone_preffered);
    this.CreateCandidateDetailsForm.controls[
      'is_in_work_email_preffered'
    ].setValue(this.clientData.is_in_work_email_preffered);
    this.CreateCandidateDetailsForm.controls['in_state'].setValue(
      this.clientData.in_state
    );
    this.CreateCandidateDetailsForm.controls['in_city'].setValue(
      this.clientData.in_city
    );
    this.CreateCandidateDetailsForm.controls['country'].setValue(
      this.clientData.in_country_full_name
    );

    if (this.clientData.is_personal_email_read_only == 1) {
      this.CreateCandidateDetailsForm.controls['in_personal_email'].disable();
    } else {
      this.CreateCandidateDetailsForm.controls['in_personal_email'].enable();
    }
  }

  UpdateContactsDetails(editId: any) {
    this.submitted = true;
    const data = this.clientData.contacts.find(
      (data: any) => data.id === editId
    );
    // this.updateContactForm.controls['primary_contact_static'].disable();
    // this.updateContactForm.controls['primary_contact'].disable();
    this.updateContactId = data.id;
    if (data.candidate_id) {
      this.updateContactForm.controls['primary_contact_static'].disable();
      this.updateCandidateId = data.candidate_id;
      this.updateContactForm.controls['primary_contact'].setValue(
        data.candidate.in_first_name + ' ' + data.candidate.in_last_name
      );
      this.updateContactForm.controls['primary_contact_static'].setValue(
        data.candidate.in_first_name + ' ' + data.candidate.in_last_name
      );
      this.updateContactForm.controls['contact_number'].setValue(
        data.candidate.in_personal_phone
      );
      this.updateContactForm.controls['contact_email'].setValue(
        data.candidate.in_personal_email
      );
    } else {
      this.updateContactForm.controls['primary_contact_static'].enable();
      this.updateCandidateId = undefined;
      this.updateContactForm.controls['primary_contact'].reset();
      this.updateContactForm.controls['primary_contact_static'].setValue(
        data.name
      );
      this.updateContactForm.controls['contact_number'].setValue(data.phone);
      this.updateContactForm.controls['contact_email'].setValue(data.email);
    }
  }

  editNoteDetail(EditId: any) {
    let NoteDetail = this.clientNotesList.find((c: any) => c.id === EditId);

    if (NoteDetail.ms_mail_id) this.isEmailNotes = true;
    else this.isEmailNotes = false;

    this.UpdateNoteForm.controls['notes'].setValue(NoteDetail.note);
    this.UpdateNoteForm.controls['allow_export'].setValue(
      NoteDetail.allow_export
    );
    this.UpdateNoteForm.controls['notes_id'].setValue(EditId);
  }

  deleteProjects() {
    this.candidateService
      .detachProject(this.ClientId, this.deleteProjectId)
      .subscribe({
        next: (response: any) => {
          this.notificationService.showSuccess(response.message);
          this.onLoad();
          this.modalService.dismissAll();
          // window.location.reload();
        },
        error: (error: any) => {},
      });
  }

  open(
    content: any,
    typeOfModal: string,
    EditId?: number,
    deleteName?: string,
    size = 'md'
  ) {
    if (typeOfModal == 'UpdateCandidateDetails') {
      this.createCandidateform();
      this.getlanguage();
      this.OpenCandidateDetails();
    }
    if (typeOfModal == 'UpdateContactDetails') {
      this.createCandidateform();
      this.OpenContactDetails();
    }
    if (typeOfModal == 'deleteProject') {
      this.deleteName = deleteName;
      this.deleteProjectId = EditId;
    }

    if (typeOfModal == 'viewClient') {
      if (EditId) {
        this.showclient(EditId);
      }
    }

    if (typeOfModal == 'updateContact') {
      this.UpdateContactsDetails(EditId);
    }

    if (typeOfModal == 'deleteContact') {
      this.deleteContactId = EditId;
    }

    // Delete Client
    if (typeOfModal == 'deleteclientModal') {
      this.deleteName = deleteName;
      this.deleteClientId = EditId;
    }

    if (typeOfModal == 'UploadDocument') {
      this.submitted = false;
      this.adddocumentForm();
    }
    if (typeOfModal == 'EditDocument') {
      this.submitted = false;
      this.adddocumentForm();
      this.EditDocument(EditId);
    }
    if (typeOfModal == 'deleteDocument') {
      this.deleteName = deleteName;
      this.deleteDocumentId = EditId;
    }
    if (typeOfModal == 'editNote') {
      this.editNoteSearchName = deleteName;
      this.editNoteDetail(EditId);
    }
    if (typeOfModal == 'deleteNote') {
      this.deleteNoteId = EditId;
    }
    if (typeOfModal == 'DeleteCandidate') {
      this.deleteName = deleteName;
    }
    if (typeOfModal == 'Addtag') {
      this.selectedtagValue = null;
      this.getTags();
    }
    if (typeOfModal == 'createNote') {
      this.CreateNoteForm.controls['notes_project'].setValue(0);
    }
    if (typeOfModal == 'updateNote' || typeOfModal == 'deleteNote') {
      this.noteId = EditId;
    }
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: size })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        () => {
          //${this.getDismissReason(reason)}
          this.closeResult = `Dismissed `;
        }
      );
  }
  getTags() {
    // this.tagselect?.clearModel();
    // this.showTagDropdown=true;
    this.tagService.listAll().subscribe({
      next: (response) => {
        this.tagList = response.tags.data;
        this.tagList = this.tagList.filter((ele: any) => ele.is_active == 1);
      },
      error: (err) => {},
    });
  }
  TagsSelected() {
    const selectedTag: any = this.candidatetagList.find(
      (tag: any) => tag.id === this.selectedtagValue
    );
    if (selectedTag) {
      this.tagError = true;
      this.modalService.dismissAll();
      return;
    }
    this.candidateService
      .attachtag(this.ClientId, this.selectedtagValue)
      .subscribe({
        next: (response) => {
          this.modalService.dismissAll();
          this.onLoad();

          // window.location.reload();
        },
        error: (err) => {},
      });
  }
  dropdownselect(evt: any) {
    this.selectedProjectStatus = evt;
  }
  dropdownselectStrength(evt: any, projectId: any) {
    this.strengthId = evt;
    this.selectedStrengths[projectId] = evt;
  }

  getCandidateStatus() {
    this.candidateService.getCandidateStatus().subscribe({
      next: (response: any) => {
        this.candidateStatus = response.candidate_statuses.data;
      },
      error: (error) => {},
    });
  }

  getclientStatus() {
    this.ClientService.getClientStatus().subscribe({
      next: (response: any) => {
        this.clientStatusList = response.client_statuses.data;
        const clientStage = this.clientStatusList.find(
          (data: any) => data.id === this.clientData.client_status_id
        );
        if (clientStage) {
          this.clientStage = clientStage.name;
        }
      },
      error: (error) => {},
    });
  }

  getclientprobability() {
    this.ClientService.getclientprobability().subscribe({
      next: (response: any) => {
        this.ClientProbabiltylist = response.client_probability_statuses.data;
        const probability = this.ClientProbabiltylist.find(
          (data: any) =>
            data.id === this.clientData.client_probability_status_id
        );
        this.clientProbability = probability?.name;
      },
      error: (error) => {},
    });
  }

  onTagSearch(term: any) {
    let searchValue = term.term;
    if (true) {
      // searchValue.length>=2
      let data = { search: searchValue };
      this.tagService.searchTagList(data).subscribe({
        next: (response: any) => {
          this.tagList = response.tags.data;
          this.tagList = this.tagList.filter((ele: any) => ele.is_active == 1);
        },
        error: (err) => {},
      });
    }
  }
  returnToBack() {
    setTimeout(() => {
      this.router.navigate(['/client-list']);
    }, 200);
  }

  searchPeopleInCompany() {
    let candidateStorage: any = this.storageService.getValue(
      StorageKey.listSettings
    );
    candidateStorage = JSON.parse(candidateStorage);
    //  candidateStorage.candidates.search = this.clientData.current_company;
    this.storageService.setValue(
      'listSettings',
      JSON.stringify({
        candidates: { apply_filter: this.clientData.current_company },
      })
    );
    candidateStorage.candidates.apply_filter =
      'search_current_company=' + this.clientData.current_company;
    this.storageService.setValue(
      StorageKey.listSettings,
      JSON.stringify(candidateStorage)
    );
    this.router.navigate(['/candidate-list']);
  }

  onProjectSearch(term: any) {
    let searchValue = term.term;
    if (true) {
      // searchValue.length>=2
      let data = {
        is_public: 0,
        search: searchValue,
      };

      this.projectService.searchProjectList(data).subscribe({
        next: (response: any) => {
          this.projectLists = response.projects.data;
          this.projectLists = this.projectLists.filter(
            (ele: any) => ele.project_status_id == 1
          );
          this.projectLists.forEach((ele: any) => {
            ele['dropdown'] =
              ele?.name +
              (ele?.client?.name ? ' [' + ele?.client?.name + ']' : '');
          });
        },
        error: (err: any) => {},
      });
    }
  }

  getInitials(name: string): string {
    const words = name.split(' ');
    let initials = '';

    for (let i = 0; i < words.length; i++) {
      const word = words[i].trim();

      if (word.length > 0) {
        initials += word[0].toUpperCase();
      }
    }

    return initials;
  }

  getClientNotes() {
    let data = {
      contact_id: this.createNoteForm['client_notes'].value || undefined,
      client_id: this.ClientId,
      sort_field: 'updated_at',
      sort_method: 'desc',
    };

    this.notesService.getClientNotes(data).subscribe({
      next: (response: any) => {
        this.clientNotesList = response.notes.data;
        this.clientNotesList.forEach((element: any) => {
          element['initials'] = this.getInitials(element.creator.name);
        });
      },
      error: (error) => {},
    });
  }

  getClientName(id: any) {
    const client = this.clientsData.find((data: any) => data.id === id);
    return client?.name || '';
  }

  deleteNoteDetail() {
    this.notesService.deleteClientNote(this.noteId).subscribe({
      next: (response) => {
        window.location.reload();
        this.getClientNotes();
        this.modalService.dismissAll();
        this.notificationService.showSuccess(response.message);
      },
      error: (error) => {},
    });
  }

  deleteContactById() {
    this.notesService
      .deleteContact(this.ClientId, this.deleteContactId)
      .subscribe({
        next: (response) => {
          window.location.reload();
          this.modalService.dismissAll();
          this.notificationService.showSuccess(response.message);
          this.getClientData();
          this.getClientNotes();
        },
        error: (error) => {},
      });
  }

  getContactLabel(label: any) {
    if (label.candidate_id) {
      return label.candidate.in_first_name + ' ' + label.candidate.in_last_name;
    } else {
      return label.name;
    }
  }

  updateNoteDetail() {
    this.updatesubmitted = true;
    // const contactId = this.clientNotesList.find((data:any)=>);
    let data = {
      note: this.UpdateNoteForm.controls['notes'].value,
    };

    this.projectapicall = true;
    this.notesService
      .updateClientNote(this.updateNoteForm['notes_id'].value, data)
      .subscribe({
        next: (response) => {
          window.location.reload();
          this.getClientNotes();
          this.modalService.dismissAll();
          this.updatesubmitted = false;
          this.notificationService.showSuccess(response.message);
          this.projectapicall = false;
        },
        error: (error) => {
          this.projectapicall = false;
          this.UpdateNotesError = error.error.message;
          if (error.status === 422) {
            if (error.error.note) {
              this.UpdateNotesErrorNote = error.error.note[0];
            }
            if (error.error.allow_export) {
              this.UpdateNotesErrorAllowexport = error.error.allow_export[0];
            }
          }
        },
      });
  }
  linkedinRefresh(popupForLinkedinContent: any) {
    if (this.clientData?.linkedin_url === null) {
      this.openUrlModal(popupForLinkedinContent);
      return;
    }
    this.candidateService.linkedinRefresh(this.ClientId).subscribe({
      next: (response: any) => {
        this.refreshDate = response.candidate.refreshed_at;
        this.notificationService.showSuccess(response.message);
        window.location.reload();
      },
      error: (err: any) => {},
    });
  }

  Attacherror() {
    this.createAttachErrorProjectid = '';
    this.createAttachError = '';
  }

  projectSelected() {
    if (this.selectedProject || this.selectedProject != null) {
      this.candidateService
        .attachProject(this.ClientId, this.selectedProject)
        .subscribe({
          next: (response: any) => {
            this.notificationService.showSuccess(response.message);
            this.onLoad();
            this.modalService.dismissAll();
            window.location.reload();
          },
          error: (error: any) => {
            this.createAttachError = error.error.message;
            if (error.status === 422) {
              if (error.error.project_id) {
                this.createAttachErrorProjectid = error.error.project_id[0];
              }
            }
          },
        });
    }
  }

  createCandidate() {
    this.submitted = true;

    if (this.CreateCandidateForm.invalid) return;

    if (this.createCandidateForm['linkedInUrl'].value.trim() == '') {
      this.notificationService.showError('Please Enter LinkedIn URL');
      return;
    }

    this.createCandidateData = {
      url: this.createCandidateForm['linkedInUrl'].value,
    };

    this.candidateService.create(this.createCandidateData).subscribe({
      next: (response) => {},
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openUrlModal(popupForLinkedinContent: any) {
    this.modalService
      .open(popupForLinkedinContent, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  // attachLogoFiles(event: any) {

  //   this.files2 = event.target.files[0];
  //   this.fileName2 = this.files2.name;
  //   this.filePath2 = event.target.value;

  //   if (event.target.files) {
  //     var reader = new FileReader;
  //     reader.onload = (event: any) => {
  //       this.url = event.target.result;
  //     }
  //     reader.readAsDataURL(event.target.files[0]);
  //     const formData: FormData = new FormData();
  //     formData.append('name', this.fileName2);
  //     formData.append('profile_pic', this.files2);
  //     this.projectapicall = true;
  //     this.candidateService.updateCandidateData(this.ClientId, formData).subscribe({
  //       next: (response) => {
  //         this.modalService.dismissAll();

  //         this.notificationService.showSuccess(response.message);
  //         this.projectapicall = false;
  //         this.submitted = false;
  //         this.CreateCandidateDetailsForm.reset();
  //         this.onLoad();

  //       },
  //       error: (error) => {
  //         this.projectapicall = false;
  //         this.CreateCandidateDetailsError = error.error.message;
  //         switch (error.status) {
  //           case 422:
  //             if (error.error.in_first_name) {
  //               this.CreateCandidateDetailsErrorFirstname = error.error.in_first_name[0];
  //             }
  //             if (error.error.in_last_name) {
  //               this.CreateCandidateDetailsErrorLastname = error.error.in_last_name[0];
  //             }
  //             if (error.error.in_gender) {
  //               this.CreateCandidateDetailsErrorGender = error.error.in_gender[0];
  //             }
  //             if (error.error.in_birth_date) {
  //               this.CreateCandidateDetailsErrorInbirthdate = error.error.in_birth_date[0];
  //             }
  //             if (error.error.current_company) {
  //               this.CreateCandidateDetailsErrorCurrentcompany = error.error.current_company[0];
  //             }
  //             if (error.error.current_position) {
  //               this.CreateCandidateDetailsErrorCurrentposition = error.error.current_position[0];
  //             }
  //             if (error.error.custom_diversity) {
  //               this.CreateCandidateDetailsErrorCustomdiversity = error.error.custom_diversity[0];
  //             }
  //             if (error.error.custom_compensation_details) {
  //               this.CreateCandidateDetailsErrorCustomcompensationdetails = error.error.custom_compensation_details[0];
  //             }
  //             if (error.error.language_ids) {
  //               this.CreateCandidateDetailsErrorLanguageids = error.error.language_ids[0];
  //             }
  //             if (error.error.linkedin_url) {
  //               this.CreateCandidateDetailsErrorLinkedinurl = error.error.linkedin_url[0];
  //             }
  //             break;
  //           default:
  //           //Nothing
  //         }
  //         // this.notificationService.showError(error.message);
  //       }
  //     })

  //   }

  // }

  attachLogoFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;

    if (event.target.files) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.url = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);

      // Your existing upload logic
      const formData: FormData = new FormData();
      formData.append('name', this.fileName);
      formData.append('profile_pic', this.files);
      this.projectapicall = true;
      this.candidateService
        .updateCandidateData(this.ClientId, formData)
        .subscribe({
          next: (response) => {
            this.modalService.dismissAll();
            this.notificationService.showSuccess(response.message);
            this.projectapicall = false;
            this.submitted = false;
            this.CreateCandidateDetailsForm.reset();
            this.onLoad();
          },
          error: (error) => {
            this.projectapicall = false;
            this.CreateCandidateDetailsError = error.error.message;
          },
        });
    }
  }

  capitalizeFirstLetter(str: string): string {
    if (!str) return '---'; // If the string is empty or undefined, return '---'
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private getURLPattern(): string {
    return '^(http|https)://([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$';
  }

  getUsersList(term: any = null) {
    let currentTenant = this.storageService.getValue(
      StorageKey.currentTenantId
    );
    if (currentTenant == '0') {
      return;
    }
    let data = {};
    if (term && term.term) {
      data = { search: term.term };
    }
    this.userService.listByFilter(data).subscribe({
      next: (response) => {
        this.userList = response.users.data;
        let loginUserDetail: any = this.storageService.getValue(
          StorageKey.currentUser
        );
        let currentUser = JSON.parse(loginUserDetail);
        if (currentUser.is_super_admin != 1) {
          let currentUserid = this.storageService.getValue(
            StorageKey.currentUserid
          );
          let isUserIdInList: boolean = false;
          this.userList.forEach((e: any) => {
            if (e.id == currentUserid) {
              isUserIdInList = true;
            }
          });
          if (!isUserIdInList) {
            this.userList.push(currentUser);
          }
        }
      },
      error: (error) => {},
    });
  }

  populateEmail(event: any) {
    if (event && event.id != undefined) {
      this.f['primary_contact_static'].disable();
      this.static_primary_contact = false;
      this.candidateService.candidateDetail(event.id).subscribe({
        next: (response: any) => {
          this.addCandidateId = response.candidate.id;
          this.f['contact_number'].setValue(
            response.candidate.in_personal_phone
          );
          this.f['contact_email'].setValue(
            response.candidate.in_personal_email
          );
          this.f['primary_contact_static'].setValue(event.full_name);
        },
        error: () => {},
      });
    } else {
      this.f['primary_contact_static'].enable();
      this.f['contact_number'].setValue('');
      this.f['contact_email'].setValue('');
      this.f['primary_contact_static'].setValue('');

      // this.updateContactForm.controls['primary_contact_static'].enable();
      // this.updateContactForm.controls['contact_number'].setValue('');
      // this.updateContactForm.controls['contact_email'].setValue('');
      // this.updateContactForm.controls['primary_contact_static'].setValue('');
    }
  }

  populateClientEmail(event: any) {
    if (event && event.id != undefined) {
      this.updateContactForm.controls['primary_contact_static'].disable();
      this.static_primary_contact = false;
      this.candidateService.candidateDetail(event.id).subscribe({
        next: (response: any) => {
          this.addCandidateId = response.candidate.id;
          this.updateContactForm.controls['contact_number'].setValue(
            response.candidate.in_personal_phone
          );
          this.updateContactForm.controls['contact_email'].setValue(
            response.candidate.in_personal_email
          );
          this.updateContactForm.controls['primary_contact_static'].setValue(
            event.full_name
          );
        },
        error: () => {},
      });
    } else {
      this.updateContactForm.controls['primary_contact_static'].enable();
      this.updateContactForm.controls['contact_number'].setValue('');
      this.updateContactForm.controls['contact_email'].setValue('');
      this.updateContactForm.controls['primary_contact_static'].setValue('');

      // this.updateContactForm.controls['primary_contact_static'].enable();
      // this.updateContactForm.controls['contact_number'].setValue('');
      // this.updateContactForm.controls['contact_email'].setValue('');
      // this.updateContactForm.controls['primary_contact_static'].setValue('');
    }
  }

  getCandidatePhoneNumber(selected_value = '') {
    this.candidateService.listByFilter({ search: selected_value }).subscribe({
      next: (response) => {
        this.candidateList = response.candidates.data;

        this.candidateList.forEach((element: any) => {
          element.full_name =
            element.in_first_name +
            ' ' +
            element.in_last_name +
            ' ' +
            (element.current_company
              ? '[' + element.current_company + ']'
              : '');
        });
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getStateList() {
    this.clientService.listStateAll().subscribe({
      next: (response) => {
        this.stateList = response.states;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getDocumentData() {
    this.clientService.getDocumentData(this.ClientId).subscribe({
      next: (response) => {
        this.documentData = response.client.documents;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  deleteDocuments1(deleteId: any) {
    this.clientService.deleteDocument(this.ClientId, deleteId).subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);
        this.getDocumentData();
      },
      error: (err: any) => {},
    });
  }

  UpdateClients() {
    this.submitted = true;
    if (this.CreateClientForm.invalid) {
      return;
    }

    let UpdatedClientDetails = {
      id: this.CreateClientForm.controls['ClientId'].value,
      name: this.CreateClientForm.controls['name'].value,
      user_id: this.CreateClientForm.controls['user_id'].value,
      industry: this.CreateClientForm.controls['industry'].value,
      city: this.CreateClientForm.controls['city'].value,
      state: this.CreateClientForm.controls['state'].value,
      // contact_email: this.CreateClientForm.controls['contact_email'].value,
      // contact_number: this.CreateClientForm.controls['contact_number'].value,
      postal_code: this.CreateClientForm.controls['postal_code'].value,
      street_address: this.CreateClientForm.controls['street_address'].value,
      description: this.CreateClientForm.controls['description'].value,
      website: this.CreateClientForm.controls['website'].value,
      // primary_contact: this.CreateClientForm.controls['primary_contact'].value
      // ? this.CreateClientForm.controls['primary_contact'].value
      // : this.CreateClientForm.controls['primary_contact_static'].value,
      client_status_id:
        this.CreateClientForm.controls['client_status_id'].value == null
          ? 0
          : this.CreateClientForm.controls['client_status_id'].value,
      web_link_one: this.CreateClientForm.controls['web_link_one'].value,
      web_link_two: this.CreateClientForm.controls['web_link_two'].value,
      web_link_three: this.CreateClientForm.controls['web_link_three'].value,
      // candidate_id: this.CreateClientForm.controls['candidate_id'].value,
      client_probability_status_id:
        this.CreateClientForm.controls['client_probability_status_id'].value ==
        null
          ? 0
          : this.CreateClientForm.controls['client_probability_status_id']
              .value,
      is_referenceable: this.CreateClientForm.controls['is_referenceable'].value
        ? 1
        : 0,
    };

    this.projectapicall = true;
    const formData: FormData = new FormData();

    if (this.fileName2 != null) {
      formData.append('logoname', this.fileName2);
      formData.append('logo', this.files2);
      formData.append(
        'ClientId',
        this.CreateClientForm.controls['ClientId'].value
      );
      formData.append('name', this.CreateClientForm.controls['name'].value);
      formData.append(
        'user_id',
        this.CreateClientForm.controls['user_id'].value
      );
      formData.append(
        'industry',
        this.CreateClientForm.controls['industry'].value
      );
      formData.append('city', this.CreateClientForm.controls['city'].value);
      formData.append('state', this.CreateClientForm.controls['state'].value);
      // formData.append(
      //   'contact_email',
      //   this.CreateClientForm.controls['contact_email'].value
      // );
      // formData.append(
      //   'contact_number',
      //   this.CreateClientForm.controls['contact_number'].value
      // );
      formData.append(
        'postal_code',
        this.CreateClientForm.controls['postal_code'].value
      );
      formData.append(
        'street_address',
        this.CreateClientForm.controls['street_address'].value
      );
      formData.append(
        'description',
        this.CreateClientForm.controls['description'].value
      );
      formData.append(
        'website',
        this.CreateClientForm.controls['website'].value
      );
      // formData.append(
      //   'primary_contact',
      //   this.CreateClientForm.controls['primary_contact'].value
      //     ? this.CreateClientForm.controls['primary_contact'].value
      //     : this.CreateClientForm.controls['primary_contact_static'].value
      // );
      formData.append(
        'client_status_id',
        this.CreateClientForm.controls['client_status_id'].value == null
          ? 0
          : this.CreateClientForm.controls['client_status_id'].value
      );
      formData.append(
        'web_link_one',
        this.CreateClientForm.controls['web_link_one'].value
      );
      formData.append(
        'web_link_two',
        this.CreateClientForm.controls['web_link_two'].value
      );
      formData.append(
        'web_link_three',
        this.CreateClientForm.controls['web_link_three'].value
      );
      // formData.append(
      //   'candidate_id',
      //   this.CreateClientForm.controls['candidate_id'].value
      // );
      formData.append(
        'client_probability_status_id',
        this.CreateClientForm.controls['client_probability_status_id'].value ==
          null
          ? 0
          : this.CreateClientForm.controls['client_probability_status_id'].value
      );
      formData.append(
        'is_referenceable',
        this.CreateClientForm.controls['is_referenceable'].value ? '1' : '0'
      );
    } else if (this.fileName2 == null) {
      formData.append(
        'ClientId',
        this.CreateClientForm.controls['ClientId'].value
      );
      formData.append('name', this.CreateClientForm.controls['name'].value);
      formData.append(
        'user_id',
        this.CreateClientForm.controls['user_id'].value
      );
      formData.append(
        'industry',
        this.CreateClientForm.controls['industry'].value
      );
      formData.append('city', this.CreateClientForm.controls['city'].value);
      formData.append('state', this.CreateClientForm.controls['state'].value);
      // formData.append(
      //   'contact_email',
      //   this.CreateClientForm.controls['contact_email'].value
      // );
      // formData.append(
      //   'contact_number',
      //   this.CreateClientForm.controls['contact_number'].value
      // );
      formData.append(
        'postal_code',
        this.CreateClientForm.controls['postal_code'].value
      );
      formData.append(
        'street_address',
        this.CreateClientForm.controls['street_address'].value
      );
      formData.append(
        'description',
        this.CreateClientForm.controls['description'].value
      );
      formData.append(
        'website',
        this.CreateClientForm.controls['website'].value
      );
      // formData.append(
      //   'primary_contact',
      //   this.CreateClientForm.controls['primary_contact'].value
      //     ? this.CreateClientForm.controls['primary_contact'].value
      //     : this.CreateClientForm.controls['primary_contact_static'].value
      // );
      formData.append(
        'client_status_id',
        this.CreateClientForm.controls['client_status_id'].value == null
          ? 0
          : this.CreateClientForm.controls['client_status_id'].value
      );
      formData.append(
        'web_link_one',
        this.CreateClientForm.controls['web_link_one'].value
      );
      formData.append(
        'web_link_two',
        this.CreateClientForm.controls['web_link_two'].value
      );
      formData.append(
        'web_link_three',
        this.CreateClientForm.controls['web_link_three'].value
      );
      // formData.append(
      //   'candidate_id',
      //   this.CreateClientForm.controls['candidate_id'].value
      // );
      formData.append(
        'client_probability_status_id',
        this.CreateClientForm.controls['client_probability_status_id'].value ==
          null
          ? 0
          : this.CreateClientForm.controls['client_probability_status_id'].value
      );
      formData.append(
        'is_referenceable',
        this.CreateClientForm.controls['is_referenceable'].value ? '1' : '0'
      );
    }

    this.clientService.updateClientData(this.ClientId, formData).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateClientForm.reset();
        this.notificationService.showSuccess(response.message);
        //  window.location.reload();
        this.projectapicall = false;
        this.submitted = false;
        // this.onLoad();
        window.location.reload();
        //  this.getClientList({});
        for (let i = 0; i < this.clientList.length; i++) {
          if (this.clientList[i].id === UpdatedClientDetails.id) {
            this.clientList[i] = response.client;
            break;
          }
        }
      },
      error: (error) => {
        this.projectapicall = false;
        this.createClientError = error.error.message;
        // this.createClientError = error.error.url;
        if (error.status === 422) {
          if (error.error.name) {
            this.createClientErrorName = error.error.name[0];
          }
          if (error.error.industry) {
            this.createClientErrorIndustry = error.error.industry[0];
          }
          if (error.error.contact_number) {
            this.createClientErrorContactnumber = error.error.contact_number[0];
          }
          if (error.error.contact_email) {
            this.createClientErrorEmail = error.error.contact_email[0];
          }
          if (error.error.street_address) {
            this.createClientErrorAddress = error.error.street_address[0];
          }
          if (error.error.postal_code) {
            this.createClientErrorPostal = error.error.postal_code[0];
          }
          if (error.error.city) {
            this.createClientErrorCity = error.error.city[0];
          }
          if (error.error.state) {
            this.createClientErrorState = error.error.state[0];
          }
          if (error.error.website) {
            this.createClientErrorWebsite = error.error.website[0];
          }
          if (error.error.description) {
            this.createClientErrorDescription = error.error.description[0];
          }
          if (error.error.primary_contact) {
            this.createClientErrorPrimarycontact =
              error.error.primary_contact[0];
          }
          if (error.error.web_link_one) {
            this.createClientErrorWeblinkone = error.error.web_link_one[0];
          }
          if (error.error.web_link_two) {
            this.createClientErrorWeblinktwo = error.error.web_link_two[0];
          }
          if (error.error.web_link_three) {
            this.createClientErrorWeblinkthree = error.error.web_link_three[0];
          }
          if (error.error.logo) {
            this.createClientErrorLogo = error.error.logo[0];
          }
        }
      },
    });
  }

  resetBackendClienterror() {
    this.createClientErrorName = '';
    this.createClientErrorIndustry = '';
    this.createClientErrorContactnumber = '';
    this.createClientErrorEmail = '';
    this.createClientErrorAddress = '';
    this.createClientErrorPostal = '';
    this.createClientErrorCity = '';
    this.createClientErrorState = '';
    this.createClientErrorWebsite = '';
    this.createClientErrorPrimarycontact = '';
    this.createClientErrorCity = '';
    this.createClientErrorPrimarycontact = '';
    this.createClientErrorWeblinkone = '';
    this.createClientErrorWeblinktwo = '';
    this.createClientErrorWeblinkthree = '';
    this.createClientErrorLogo = '';
    this.CreatDocumentsErrorDocuments = '';
    this.createClientError = '';
    this.CreatDocumentsErrorDocumentsname = '';
    this.CreateClientForm.reset();
  }

  attachFiles2(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;
    this.showUploadFileButton = true;
  }

  UploadDocuments2() {
    this.showUploadFileButton = false;
    this.uploadsubmitted = true;
    if (!this.files) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('name', this.fileName);
    formData.append('document', this.files);

    this.projectapicall2 = true;

    this.clientService.createDocument(this.ClientId, formData).subscribe({
      next: (response: any) => {
        // this.onLoad();
        // this.modalService.dismissAll();
        this.notificationService.showSuccess(response.message);
        this.projectapicall2 = false;
        this.uploadsubmitted = false;
        this.getDocumentData();
        this.CreateClientForm.controls['document'].setValue(null);
      },
      error: (error) => {
        this.projectapicall2 = false;
        if (error.status === 422) {
          if (error.error.document) {
            this.CreatDocumentsErrorDocuments = error.error.document[0];
          }

          if (error.error.name) {
            this.CreatDocumentsErrorDocumentsname = error.error.name[0];
          }
        }
      },
    });
  }

  attachLogoFiles2(event: any) {
    this.files2 = event.target.files[0];
    this.fileName2 = this.files2.name;
    this.filePath2 = event.target.value;

    if (event.target.files) {
      this.logoBox2 = true;
      this.rectangleBox = false;
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.url2 = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
}
