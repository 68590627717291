<app-app-sub-header
  class="top-row d-flex justify-content-between"
  (onRefreshClicked)="resetListSettings()"
  (searchClicked)="searchData($event)"
  [isSearchable]="isSearchable"
  [pageTitle]="pageTitle"
  [showCreateButton]="showCreateButton"
  [EditId]="EditId"
></app-app-sub-header>
<div class="bottom-table">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th
          scope="col"
          style="width: 18%"
          (mouseenter)="mouseEnter('name')"
          (mouseleave)="mouseLeave('name')"
        >
          Tenant Name
          <span
            *ngIf="currentSortedColumn != 'name' && mouseOnColumnName == 'name'"
            (click)="
              getTenantList({
                sort_field: 'name',
                sort_method: 'desc',
                column_name: 'name'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            *ngIf="currentSortedColumn == 'name'"
            [hidden]="!iconVisibleDesc"
            (click)="
              getTenantList({
                sort_field: 'name',
                sort_method: 'asc',
                column_name: 'name'
              })
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            *ngIf="currentSortedColumn == 'name'"
            [hidden]="!iconVisibleAsc"
            (click)="
              getTenantList({
                sort_field: 'name',
                sort_method: 'desc',
                column_name: 'name'
              })
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>

        <th
          scope="col"
          style="width: 9%"
          (mouseenter)="mouseEnter('id')"
          (mouseleave)="mouseLeave('id')"
        >
          Key
          <span
            *ngIf="currentSortedColumn != 'id' && mouseOnColumnName == 'id'"
            (click)="
              getTenantList({
                sort_field: 'id',
                sort_method: 'desc',
                column_name: 'id'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            *ngIf="currentSortedColumn == 'id'"
            [hidden]="!iconVisibleDesc"
            (click)="
              getTenantList({
                sort_field: 'id',
                sort_method: 'asc',
                column_name: 'id'
              })
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            *ngIf="currentSortedColumn == 'id'"
            [hidden]="!iconVisibleAsc"
            (click)="
              getTenantList({
                sort_field: 'id',
                sort_method: 'desc',
                column_name: 'id'
              })
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>

        <th
          scope="col"
          style="width: 14%"
          (mouseenter)="mouseEnter('admin_name')"
          (mouseleave)="mouseLeave('admin_name')"
        >
          Admin Name
          <span
            *ngIf="
              currentSortedColumn != 'admin_name' &&
              mouseOnColumnName == 'admin_name'
            "
            (click)="
              getTenantList({
                sort_field: 'admin_name',
                sort_method: 'desc',
                column_name: 'admin_name'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            *ngIf="currentSortedColumn == 'admin_name'"
            [hidden]="!iconVisibleDesc"
            (click)="
              getTenantList({
                sort_field: 'admin_name',
                sort_method: 'asc',
                column_name: 'admin_name'
              })
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            *ngIf="currentSortedColumn == 'admin_name'"
            [hidden]="!iconVisibleAsc"
            (click)="
              getTenantList({
                sort_field: 'admin_name',
                sort_method: 'desc',
                column_name: 'admin_name'
              })
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>

        <th
          scope="col"
          style="width: 14%"
          (mouseenter)="mouseEnter('admin_email')"
          (mouseleave)="mouseLeave('admin_email')"
        >
          Admin Email
          <span
            *ngIf="
              currentSortedColumn != 'admin_email' &&
              mouseOnColumnName == 'admin_email'
            "
            (click)="
              getTenantList({
                sort_field: 'admin_email',
                sort_method: 'desc',
                column_name: 'admin_email'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            *ngIf="currentSortedColumn == 'admin_email'"
            [hidden]="!iconVisibleDesc"
            (click)="
              getTenantList({
                sort_field: 'admin_email',
                sort_method: 'asc',
                column_name: 'admin_email'
              })
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            *ngIf="currentSortedColumn == 'admin_email'"
            [hidden]="!iconVisibleAsc"
            (click)="
              getTenantList({
                sort_field: 'admin_email',
                sort_method: 'desc',
                column_name: 'admin_email'
              })
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>

        <th
          scope="col"
          style="width: 18%"
          (mouseenter)="mouseEnter('description')"
          (mouseleave)="mouseLeave('description')"
        >
          Description
          <span
            *ngIf="
              currentSortedColumn != 'id' && mouseOnColumnName == 'admin_email'
            "
            (click)="
              getTenantList({
                sort_field: 'admin_email',
                sort_method: 'desc',
                column_name: 'admin_email'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            *ngIf="currentSortedColumn == 'admin_email'"
            [hidden]="!iconVisibleDesc"
            (click)="
              getTenantList({
                sort_field: 'admin_email',
                sort_method: 'asc',
                column_name: 'admin_email'
              })
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            *ngIf="currentSortedColumn == 'admin_email'"
            [hidden]="!iconVisibleAsc"
            (click)="
              getTenantList({
                sort_field: 'admin_email',
                sort_method: 'desc',
                column_name: 'admin_email'
              })
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>

        <th
          scope="col"
          style="width: 14%"
          (mouseenter)="mouseEnter('website')"
          (mouseleave)="mouseLeave('website')"
        >
          Website
          <span
            *ngIf="
              currentSortedColumn != 'id' && mouseOnColumnName == 'website'
            "
            (click)="
              getTenantList({
                sort_field: 'website',
                sort_method: 'desc',
                column_name: 'website'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            *ngIf="currentSortedColumn == 'website'"
            [hidden]="!iconVisibleDesc"
            (click)="
              getTenantList({
                sort_field: 'website',
                sort_method: 'asc',
                column_name: 'website'
              })
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            *ngIf="currentSortedColumn == 'website'"
            [hidden]="!iconVisibleAsc"
            (click)="
              getTenantList({
                sort_field: 'website',
                sort_method: 'desc',
                column_name: 'website'
              })
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>

        <th
          scope="col"
          style="width: 8%; text-align: center !important"
          (mouseenter)="mouseEnter('is_active')"
          (mouseleave)="mouseLeave('is_active')"
        >
          Status
          <span
            *ngIf="
              currentSortedColumn != 'id' && mouseOnColumnName == 'is_active'
            "
            (click)="
              getTenantList({
                sort_field: 'is_active',
                sort_method: 'desc',
                column_name: 'is_active'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            *ngIf="currentSortedColumn == 'is_active'"
            [hidden]="!iconVisibleDesc"
            (click)="
              getTenantList({
                sort_field: 'is_active',
                sort_method: 'asc',
                column_name: 'is_active'
              })
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            *ngIf="currentSortedColumn == 'is_active'"
            [hidden]="!iconVisibleAsc"
            (click)="
              getTenantList({
                sort_field: 'is_active',
                sort_method: 'desc',
                column_name: 'is_active'
              })
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>

        <th scope="col" style="width: 5%">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let tenant of tenantList">
        <td>
          <div class="form-check">
            <label class="form-check-label" for="flexCheckDefault">
              <div class="top-left-details w-60 d-flex align-middle">
                <div class="profile-name1">
                  <img
                    *ngIf="tenant.logo_path !== null"
                    class="tenantlogo"
                    style="padding-left: 0px"
                    src="{{ tenant.logo_path }}"
                    alt=""
                  />
                  <span
                    *ngIf="tenant.logo_path === null"
                    class="initial"
                    style="padding-left: 0px"
                    >{{ tenant.initialss }}</span
                  >
                </div>
                <div class="top_right_profile ps-3 d-inline-block">
                  <p>
                    <span style="padding-left: 0px">{{ tenant.name }}</span>
                  </p>
                </div>
              </div>
            </label>
          </div>
        </td>
        <td>{{ !tenant?.id || tenant?.id == "null" ? "" : tenant?.id }}</td>
        <td>
          {{
            !tenant?.admin_name || tenant?.admin_name == "null"
              ? ""
              : tenant?.admin_name
          }}
        </td>
        <td>
          {{
            !tenant?.admin_email || tenant?.admin_email == "null"
              ? ""
              : tenant?.admin_email
          }}
        </td>
        <td style="text-align: left">
          {{
            !tenant?.description || tenant?.description == "null"
              ? ""
              : tenant?.description
          }}
        </td>
        <td style="text-align: left">{{ tenant?.website || "-" }}</td>
        <td style="text-align: center !important">
          <span *ngIf="tenant.is_active === 1" class="badge text-bg-success"
            >Active</span
          >
          <span *ngIf="tenant.is_active === 0" class="badge text-bg-warning"
            >Inactive</span
          >
        </td>
        <td>
          <div style="cursor: pointer">
            <a (click)="open(UpdateTenant, tenant.id); imgErrorMessage = ''"
              ><em class="bi bi-pencil-square me-3" title="Edit"></em
            ></a>
            <a
              (click)="openDeleteModal(content, tenant.id, tenant)"
              [hidden]="userid == tenant.id"
            >
              <em class="bi bi-trash" title="Delete"></em
            ></a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="pagination-main">
  <label for="" class="list-totalrecords">Total Records: {{ totalRows }}</label>
  <ul
    *ngIf="pager.pages && pager.pages.length && totalRows > pageSize"
    class="pagination justify-content-end m-0"
  >
    <li class="page-item" [ngClass]="{ disabled: pager.currentPage === 1 }">
      <a
        *ngIf="pager.currentPage === 1; else elseFirst"
        class="page-link page-show"
        ><<</a
      >
      <ng-template #elseFirst>
        <a (click)="setPage(1)" class="page-link"><<</a>
      </ng-template>
    </li>
    <li class="page-item" [ngClass]="{ disabled: pager.currentPage === 1 }">
      <a
        *ngIf="pager.currentPage === 1; else elsePrevious"
        class="page-link page-show"
      >
        <</a
      >
      <ng-template #elsePrevious>
        <a (click)="setPage(pager.currentPage - 1)" class="page-link"> <</a>
      </ng-template>
    </li>

    <li class="page-item">
      <a class="page-link">{{ pager.currentPage }}</a>
    </li>
    <li
      class="page-item"
      [ngClass]="{ disabled: pager.currentPage === pager.totalPages }"
    >
      <a
        *ngIf="pager.currentPage === pager.totalPages; else elseNext"
        class="page-link page-show"
        >></a
      >
      <ng-template #elseNext>
        <a (click)="setPage(pager.currentPage + 1)" class="page-link">></a>
      </ng-template>
    </li>
    <li
      class="page-item"
      [ngClass]="{ disabled: pager.currentPage === pager.totalPages }"
    >
      <a
        *ngIf="pager.currentPage === pager.totalPages; else elseLast"
        class="page-link page-show"
        >>></a
      >
      <ng-template #elseLast>
        <a (click)="setPage(pager.totalPages)" class="page-link">>></a>
      </ng-template>
    </li>
  </ul>
</div>
<!-- <ng-template #UpdateTenant let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Edit Tenant</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form [formGroup]="CreateUserForm">  
      <div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserError">{{createUserError}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createUserErrorAvatar">{{createUserErrorAvatar}}</div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorName">{{createUserErrorName}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createUserErrorEmail">{{createUserErrorEmail}}</div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorRoleid">{{createUserErrorRoleid}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createUserErrorIsactive">{{createUserErrorIsactive}}</div>
  
      </div>
    <div class="modal-body">
    
       
      <div class="mb-3">
        <label>Name</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<b>*</b></span>
        <input type="text" placeholder="Enter name" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" >
        <div style="color: red; font-size: 14px;" *ngIf="submitted && f['name'] && f['name'].errors && f['name'].errors['required']">
           Name is required
          </div>
      </div>
  
      <div class="mb-3">
        <label>Email</label>
        <input type="text" placeholder="Enter email Id" formControlName="email" class="form-control" >
        <div style="color: red; font-size: 14px;" *ngIf="submitted && f['email'] && f['email'].errors && f['email'].errors['pattern']">
          Please enter valid Email address.
          </div> 
      </div>
  
      <div class="row">
      
        <div class="col-6">
          <div class="mb-3">
            <label>Role</label>
            <select  class="form-select" formControlName="role_id">
              <option value="1" >Admin</option>
              <option value="2">User</option>
            </select>
            <div style="color: red; font-size: 14px;" *ngIf="submitted && f['role_id'] && f['role_id'].errors && f['role_id'].errors['required']">
              User Role is required.
              </div>
          </div>
  
        </div>
             
        <div class="col-6">
          <label> Status</label>
          <select name="" id="" class="form-select" formControlName="is_active">
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
          <div style="color: red; font-size: 14px;" *ngIf="submitted && f['is_active'] && f['is_active'].errors && f['is_active'].errors['required']">
             Status is required.
            </div>
        </div>
      </div> 
  
      <div class="row">
        <div class="col-12">
          <div class="mb-3 password-container">
            <label>Password</label>
            <input type="password" placeholder="Enter password" formControlName="password" class="form-control" *ngIf="!showPassword" >
            <input type="text" placeholder="Enter password" formControlName="password" class="form-control" *ngIf="showPassword" >
            <i class="fa fa-eye" (click)="togglePassword()" *ngIf="showPassword" style="top: 47%;"></i>
            <i class="fa fa-eye-slash" (click)="togglePassword()" *ngIf="!showPassword" style="top: 47%;"></i>
            
                <small class="text-primary">Leave this field empty unless you want to change it.</small>
          </div>
        </div>
  
  
      </div> 
    </div>
    <div class="modal-footer">
      <div class="spinner-border" role="status" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter" (click)="modal.close('Save click')" *ngIf="!projectapicall">Cancel</button>
      <button type="button" class="blue-btn ms" (click)="UpdateUsers()"  *ngIf="!projectapicall">Save</button>
    </div>
  
  </form>
  </ng-template> -->
<ng-template #UpdateTenant let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Tenant</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="
        modal.dismiss('Cross click');
        this.submitted = false;
        resetBackendTenanterror()
      "
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="CreateTenantForm">
    <div>
      <div
        style="padding: 10px; font-size: 14px; padding-left: 20px"
        class="text-danger"
        *ngIf="createTenantError"
      >
        {{ createTenantError }}
      </div>
      <div
        style="padding: 5px; font-size: 14px; padding-left: 20px"
        class="text-danger"
        *ngIf="createTenantErrorId"
      >
        {{ createTenantErrorId }}
      </div>
      <div
        style="padding: 5px; font-size: 14px; padding-left: 20px"
        class="text-danger"
        *ngIf="createTenantErrorName"
      >
        {{ createTenantErrorName }}
      </div>
      <div
        style="padding: 5px; font-size: 14px; padding-left: 20px"
        class="text-danger"
        *ngIf="createTenantErroruserName"
      >
        {{ createTenantErroruserName }}
      </div>
      <div
        style="padding: 10px; font-size: 14px; padding-left: 20px"
        class="text-danger"
        *ngIf="createTenantErroruserEmail"
      >
        {{ createTenantErroruserEmail }}
      </div>
      <div
        style="padding: 10px; font-size: 14px; padding-left: 20px"
        class="text-danger"
        *ngIf="createTenantErroruserPassword"
      >
        {{ createTenantErroruserPassword }}
      </div>
    </div>
    <div class="modal-body">
      <div style="display: flex">
        <h6 id="modal-basic-title" class="modal-title">Tenant Information</h6>
      </div>

      <div class="row" style="margin-top: 3px">
        <div>
          <label for="">Tenant Logo</label>
          <button
            type="button"
            class="blue-btn"
            (click)="selectFile.click()"
            *ngIf="logoBox"
            style="font-size: 14px !important; float: right"
          >
            Change
          </button>
        </div>
        <input
          type="file"
          id="file-input"
          style="display: none"
          accept="image/*"
          multiple
          #selectFile
          max-file-size="1024"
          formControlName="logo"
          class="form-control"
          (change)="attachLogoFiles($event)"
        />
      </div>
      <div class="row" style="margin-top: 10px">
        <div
          *ngIf="rectangleBox"
          id="panelsStayOpen-collapsesix"
          class="accordion-collapse collapse show scrolldocument"
          aria-labelledby="panelsStayOpen-headingsix"
        >
          <div class="accordion-body clientlogobg file">
            <label for="file-input" (click)="selectFile.click()"
              >Choose File</label
            >
            <input
              type="file"
              id="file-input"
              style="display: none"
              accept="image/*"
              multiple
              #selectFile
              max-file-size="1024"
              formControlName="logo"
              class="form-control"
              (change)="attachLogoFiles($event)"
            />
          </div>
        </div>
        <div class="col-lg-12 row" style="margin-top: 10px" *ngIf="logoBox">
          <div>
            <div class="col-12 position-relative" style="padding-right: 0px">
              <div style="position: relative">
                <img
                  [src]="url"
                  *ngIf="url !== null"
                  height="120"
                  width="140"
                  class="clientlogobgimg img-fluid"
                  alt=""
                />
                <a
                  type="button"
                  class="btn btn-xs btn-danger rmv"
                  (click)="resetImageFile(selectFile)"
                  style="position: absolute; bottom: 0; right: 0"
                  title="Remove image"
                  >X</a
                >
              </div>
              <small *ngIf="imgErrorMessage" style="color: red">
                * {{ imgErrorMessage }}
              </small>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-3" style="margin-top: 20px">
            <label for="">Tenant Name</label>
            <span class="text-danger" style="font-size: 14px"
              >&nbsp;<strong>*</strong></span
            >
            <input
              type="text"
              placeholder="Enter name"
              formControlName="name"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f['name'].errors }"
            />
            <div
              style="color: red; font-size: 14px"
              *ngIf="
                submitted &&
                f['name'] &&
                f['name'].errors &&
                f['name'].errors['required']
              "
            >
              Name is required.
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="">Status</label>
        <span class="text-danger" style="font-size: 14px"
          >&nbsp;<strong>*</strong></span
        >
        <select class="form-select" formControlName="is_active">
          <option value="null" disabled>Select status</option>
          <option value="1" selected>Active</option>
          <option value="0">Inactive</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="">Description</label>
        <textarea
          type="text"
          placeholder="Enter description"
          formControlName="description"
          class="form-control"
          rows="2"
        ></textarea>
      </div>
      <div class="mb-3">
        <label for="">Website</label>
        <input
          type="text"
          placeholder="Enter website"
          formControlName="website"
          class="form-control"
          rows="2"
        />
        <div
          *ngIf="
            (f['website'] &&
              f['website'].errors &&
              f['website'].errors['pattern']) ||
            tenantWebsiteError.length > 0
          "
          class="text-danger"
        >
          Please enter a valid URL. (i.e. http://www.google.com)
        </div>
      </div>

      <div class="mb-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="disable_access_to_client_module"
          />
          <label class="form-check-label" for="disable_access_to_client_module">
            &nbsp;Disable access to 'Client' module
          </label>
        </div>
        <div class="col-lg-12">
          <small class="text-primary"
            >Select this option for internal tenants</small
          >
        </div>
      </div>

      <div class="mb-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="is_public_search"
          />
          <label class="form-check-label" for="is_public_search">
            &nbsp;Enable public job posting
          </label>
        </div>
        <div class="col-lg-12">
          <small class="text-primary">Select this option for job posting</small>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div class="spinner-border" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button
        type="button"
        class="filter"
        (click)="
          modal.close('Save click');
          this.submitted = false;
          resetBackendTenanterror()
        "
        *ngIf="!projectapicall"
      >
        Cancel
      </button>
      <button
        type="button"
        class="blue-btn"
        (click)="updateTenanats()"
        *ngIf="!projectapicall"
      >
        Save
      </button>
    </div>
  </form>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Tenant</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click'); reload()"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="!tenantDeleted">
    <form>
      <div class="mb-3">
        <p *ngIf="!deleteClicked">
          Are you sure you want to delete
          <strong> {{ selectDeletedUser }}?</strong>
        </p>
      </div>
    </form>
    <p
      style="
        font-size: 14px;
        text-align: left;
        margin-top: 5px;
        margin-bottom: 10px;
      "
      *ngIf="deleteClicked"
    >
      {{ otpmessage }}
    </p>
    <input
      *ngIf="deleteClicked"
      type="text"
      style="padding-top: 20; width: 260px; height: 40px !important"
      [(ngModel)]="deleteotp"
      class="form-control"
      placeholder="Enter OTP"
    />
    <div style="font-size: 14px" class="text-danger" *ngIf="DeleteTenantError">
      {{ DeleteTenantError }}
    </div>
    <div
      style="color: red; font-size: 14px; text-align: left"
      *ngIf="showCount && deleteClicked"
    >
      Didn't receive OTP? Re-send in {{ otpCount }} seconds
    </div>
    <a
      class="sample-link"
      (click)="SendOtp()"
      *ngIf="!showCount && deleteClicked"
      >Resend OTP</a
    >
  </div>

  <div class="modal-footer" *ngIf="!tenantDeleted">
    <button
      type="button"
      class="btn btn-outline-secondary cancelbutton"
      (click)="modal.dismiss('cancel click')"
    >
      Cancel
    </button>
    <button
      type="button"
      *ngIf="!deleteClicked"
      class="btn btn-danger dangerbtn"
      (click)="SendOtp()"
    >
      Delete
    </button>
    <button
      type="button"
      *ngIf="deleteClicked"
      class="btn btn-danger dangerbtn"
      (click)="deleteTenent()"
    >
      Delete
    </button>
  </div>
  <div
    style="color: rgb(36, 112, 36); font-size: 14px; text-align: left"
    class="m-3"
    *ngIf="tenantDeleted"
  >
    {{ deleteSuccessMsg }}
  </div>
</ng-template>
