import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../common/storage.service';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService
  ) {}

  create(data: any): Observable<any> {
    return this.httpClient.post(
      `${environment.apiUrl}candidates/` + data.id + `/notes`,
      data
    );
  }

  createContactNote(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}client-notes`, data);
  }

  list(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}notes`, data);
  }

  deleteClientNote(noteId: number): Observable<any> {
    return this.httpClient.delete(
      `${environment.apiUrl}client-notes/${noteId}`
    );
  }

  deleteNote(candidateId: number, id: number): Observable<any> {
    return this.httpClient.delete(
      `${environment.apiUrl}candidates/` + candidateId + `/notes/` + id
    );
  }

  deleteContact(clientId:number, contactId:number):Observable<any> {
    return this.httpClient.delete(
      `${environment.apiUrl}clients/` + clientId + `/contacts/` + contactId
    );
  }

  updateNote(candidateId: number, noteId: number, data: any): Observable<any> {
    return this.httpClient.put(
      `${environment.apiUrl}candidates/` + candidateId + '/notes/' + noteId,
      data
    );
  }

  updateClientNote(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}client-notes/${id}`, data);
  }

  getClientContactNotes(data?: any, contactId?: any): Observable<any> {
    let get_params: string = '';
    if (data.sort_field && data.sort_method) {
      get_params +=
        `sort_field=` +
        data.sort_field +
        `&sort_method= ` +
        data.sort_method +
        `&is_client_note=` +
        data.is_client_note;
    }
    if (data.page) {
      if (get_params != '') {
        get_params += `&`;
      }
      get_params += `page=` + data.page;
    }
    if (data.search) {
      if (get_params != '') {
        get_params += `&`;
      }
      get_params += `search=` + data.search;
    }
    return this.httpClient.get(
      `${environment.apiUrl}${contactId}/contact-notes`
    );
  }

  getClientNotes(data?: any): Observable<any> {
    let get_params: string = '';
    if (data.sort_field && data.sort_method) {
      get_params +=
        `sort_field=` + data.sort_field + `&sort_method= ` + data.sort_method;
    }
    if (data.page) {
      if (get_params != '') {
        get_params += `&`;
      }
      get_params += `page=` + data.page;
    }
    if (data.client_id) {
      if (get_params != '') {
        get_params += `&`;
      }
      get_params += `client_id=` + data.client_id;
    }
    if (data.candidate_id) {
      if (get_params != '') {
        get_params += `&`;
      }
      get_params += `candidate_id=` + data.candidate_id;
    }
    if (data.contact_id) {
      if (get_params != '') {
        get_params += `&`;
      }
      get_params += `contact_id=` + data.contact_id;
    }
    return this.httpClient.get(
      `${environment.apiUrl}client-notes?` + get_params
    );
  }

  listByFilter(data?: any): Observable<any> {
    let get_params: string = '';
    if (data.sort_field && data.sort_method) {
      get_params +=
        `sort_field=` +
        data.sort_field +
        `&sort_method= ` +
        data.sort_method +
        `&is_client_note=` +
        data.is_client_note;
    }
    if (data.page) {
      if (get_params != '') {
        get_params += `&`;
      }
      get_params += `page=` + data.page;
    }
    if (data.search) {
      if (get_params != '') {
        get_params += `&`;
      }
      get_params += `search=` + data.search;
    }
    if (data.project_id >= 0) {
      if (get_params != '') {
        get_params += `&`;
      }
      get_params += `project_id=` + data.project_id;
    } else if (data.component) {
      if (get_params != '') {
        get_params += `&`;
      }
      if (data.client_id) {
        get_params +=
          `client_id=` + data.client_id + `&candidate_id=` + data.id;
      } else {
        get_params += `candidate_id=` + data.id;
      }
    }
    // else if(data.is_client_note){
    //   if(data.client_id){
    //     if(get_params != ''){
    //       get_params +=`&`
    //     }
    //     get_params += `client_id=`+ data.client_id;
    //   }
    // }

    if (data.id) {
      return this.httpClient.get(
        `${environment.apiUrl}candidates/` + data.id + `/notes?` + get_params
      );
    } else {
      return this.httpClient.get(`${environment.apiUrl}notes?` + get_params);
    }
  }
}
